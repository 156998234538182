import styled, { css } from "styled-components"
import { Layout } from "../../design_system/layout"
import { Icon, IconProps } from "../atoms"
import { Col, Container, P, Row, Spacer } from "../utilities"


export const Widget = (props: { last?: boolean, title: string, subtitle: string, icon: IconProps["variant"] }) => {

    const { last, title, subtitle, icon } = props

    const render = () =>
        <WidgetContainer last={last}>
            <Container>
                <Row>
                    <Col xs={12} justify="center">
                        <Icon variant={icon} />
                    </Col>
                    <Spacer size={16} />
                    <Col xs={12}>
                        <P center variant="secondary">
                            {title}
                        </P>
                        <Spacer size={8} />
                        <P center variant="light">
                            {subtitle}
                        </P>
                    </Col>
                </Row>
            </Container>
        </WidgetContainer>


    return render()
}

const WidgetContainer = styled.div<{ last?: boolean }>`
    position: relative;
    width: 50%;
    max-width: 50%;
    display: flex;
    padding-top: 24px;
    padding-left: 28px;
    padding-right: 28px;
    justify-content: center;
    
    @media (max-width: ${Layout.breakpoints.lg}px) {
        min-width: 350px;
        padding: 32px 32px 48px;
    }

    @media (min-width: ${Layout.breakpoints.lg + 1}px) {
        &:before {
            background-color: #ffffff3d;
            content: "";
            display: block;
            height: 16px;
            left: 0;
            position: absolute;
            top: 83px;
            width: 1px;
        }

        ${p => p.last && css`
            &:after {
                background-color: #ffffff3d;
                content: "";
                display: block;
                height: 16px;
                right: 0;
                position: absolute;
                top: 83px;
                width: 1px;
            }
        `}
    }
`