import styled, { css } from "styled-components"
import { Layout } from "../../design_system/layout"
import { Icon, IconProps } from "../atoms"
import { Col, Container, P, Row, Spacer, Visible } from "../utilities"
import { cssTransition } from "../../utilities"


export const Pillar = (props: { n: number, title: string, subtitle: string, icon: IconProps["variant"] }) => {

    const { n, title, subtitle, icon } = props

    const render = () =>
        <PillarContainer n={n}>
            <Container>
                <Row>
                    <Visible till="lg">
                        <Col xs={12} justify="start">
                            <Icon variant={icon} />
                        </Col>
                        <Spacer size={16} />
                        <Col xs={12}>
                            <P variant="secondary">
                                {title}
                            </P>
                            <Spacer size={2} />
                            <P variant="light">
                                {subtitle}
                            </P>
                        </Col>
                    </Visible>

                    <Visible over="lg">
                        <Col xs={12} justify="center">
                            <Icon variant={icon} />
                        </Col>
                        <Spacer size={16} />
                        <Col xs={12}>
                            <P center variant="secondary">
                                {title}
                            </P>
                            <Spacer size={2} />
                            <P center variant="light">
                                {subtitle}
                            </P>
                        </Col>
                    </Visible>

                </Row>
            </Container>
        </PillarContainer>

    return render()
}

const PillarContainer = styled.div<{ n: number }>`
    height: 164px;
    padding: 25px 0 32px;
    position: relative;
    width: 300px;

    @media (max-width: ${Layout.breakpoints.lg}px) {
        width: 100%;
        padding: 24px;
    }

    &:before {
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;

        ${p => p.n === 4 ? css`
            background: linear-gradient(to bottom,rgba(255,255,255,.12) 0,rgba(255,255,255,0) 100%);
        `: p.n === 1 || p.n === 2 ? css`
            background: linear-gradient(to top,rgba(255,255,255,.12) 0,rgba(255,255,255,0) 100%);
        `: ""}

        @media (max-width: ${Layout.breakpoints.lg}px) {
            ${p => p.n !== 5 ? css`
                background: linear-gradient(to right,rgba(255,255,255,.18),rgba(255,255,255,0));
                content: "";
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                right: unset;
                top: unset;
                height: 1px;
            `: ""}
        }
    }

    &:after {
            background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,rgba(255,255,255,.06) 100%);
            content: "";
            height: 100%;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            ${cssTransition(".45s cubic-bezier(.6,.6,0,1) opacity")}
            width: 100%;

            ${p => p.n === 4 || p.n === 5 ? css`
                background: linear-gradient(to top,rgba(255,255,255,0) 0%,rgba(255,255,255,.06) 100%);
            `: ""}

            @media (max-width: ${Layout.breakpoints.lg}px) {
                opacity: 1;
                width: 1px;
                background-color: #ffffff3d;
                height: 16px;
                top: 83px;
                left: -1px;
            }
    }
    
    &:hover {
        &:after {
            opacity: 1;     
        }
    }
`