import { initializeApp } from "firebase/app"
import { addDoc, collection, getFirestore } from "firebase/firestore"
import { useState } from "react"
import { useDispatch } from "react-redux"
import ReactTyped from "react-typed"
import styled from "styled-components"
import { Layout } from "../../design_system/layout"
import { setAccess } from "../../redux"
import { Button, Input } from "../atoms"
import { Col, Container, P, Row, Spacer, Visible } from "../utilities"


const Authorized = [
    "Friends!" 
]

enum PHASE {
    FIRST,
    SECOND,
    THIRD,
    FOURTH
}

export const AccessCodeForm = () => {

    const [val, setVal] = useState("")
    const [errors, setErrors] = useState("")
    const [phase, setPhase] = useState(PHASE.FIRST)

    const render = () =>
        <AccessCodeFormStyled>
            <form onSubmit={(e) => { e.preventDefault(); onConfirm() }}>
                <Container p={28}>
                    <Row>
                        <Col direction="column">
                            <P spacing=".5px" variant="light">
                                {
                                    phase === PHASE.FIRST ?
                                        <ReactTyped strings={["Welcome to BIOS. "]} typeSpeed={30} onComplete={() => window.setTimeout(() => { setPhase(PHASE.SECOND) }, 500)} /> :
                                        <>Welcome to BIOS.</>
                                }
                            </P>
                            <Spacer size={phase === PHASE.FIRST ? 0 : 4} />
                            <P spacing=".5px" variant="light">
                                {
                                    phase === PHASE.FIRST ? <></> :
                                        phase === PHASE.SECOND ?
                                            <ReactTyped strings={["Type \"Friends!\" to boot the system "]} typeSpeed={20} startDelay={500} onComplete={() => window.setTimeout(() => { setPhase(PHASE.THIRD) }, 500)} /> :
                                            <>Type "Friends!" to boot the system</>
                                }
                            </P>
                            <Spacer size={phase === PHASE.FIRST || phase === PHASE.SECOND ? 0 : 28} />
                            <P spacing=".5px" color={"#00D4FF"}>
                                {
                                    phase === PHASE.FIRST || phase === PHASE.SECOND ? <></> :
                                        phase === PHASE.THIRD ?
                                            <ReactTyped strings={["Enter your access code "]} typeSpeed={20} startDelay={500} onComplete={() => window.setTimeout(() => { setPhase(PHASE.FOURTH) }, 500)} /> :
                                            <>Enter your access code</>
                                }
                            </P>
                            {
                                phase === PHASE.FOURTH ?
                                    <Container>
                                        <Visible till="xs">
                                            <Row pt={12} justify="between" align="center">
                                                <Col>
                                                    <Input onChange={(e) => { setVal(e) }} autoFocus />
                                                </Col>
                                            </Row>
                                            <Row pt={12} justify="between" align="center">
                                                <Col xs={12}>
                                                    <Button w100 variant="secondary" onClick={onConfirm}>
                                                        Confirm
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Visible>
                                        <Visible over="xs">
                                            <Row pt={8} justify="between" align="center">
                                                <PropmtCursor />
                                                <Spacer size={12} />
                                                <Col>
                                                    <Input onChange={(e) => { setVal(e) }} autoFocus />
                                                </Col>
                                                <Spacer size={8} />
                                                <Col xs="content">
                                                    <Button variant="secondary" onClick={onConfirm}>
                                                        Confirm
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Visible>
                                    </Container>
                                    :
                                    <></>
                            }
                            {
                                errors.length > 0 &&
                                <>
                                    <Spacer size={16} />
                                    <P medium color="#f27777b5">{errors}</P>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </form>
        </AccessCodeFormStyled>


    const dispatch = useDispatch()
    const onConfirm = () => {
        if (Authorized.some(x => x === val)) {
            document.body.classList.add("authorized")

            window.setTimeout(() => {
                dispatch(setAccess(true))
            }, 1000)

            if (val !== "Amici") {
                const firebaseConfig = {
                    apiKey: "AIzaSyB3QPbj4-c9C9u5f2rCj4ruZB1svTf0oe8",
                    authDomain: "bios-82fb8.firebaseapp.com",
                    projectId: "bios-82fb8",
                    storageBucket: "bios-82fb8.appspot.com",
                    messagingSenderId: "649607546461",
                    appId: "1:649607546461:web:40863a1b27e722ca242fb4",
                    measurementId: "G-QTXQ972GB2"
                };

                initializeApp(firebaseConfig)
                const db = getFirestore()
                const colRef = collection(db, "landing_visits")

                addDoc(colRef, {
                    code: val,
                    timestamp: new Date().getTime()
                })
                    .catch((err) => { })
            }
        }
        else {
            setErrors(val.length === 0 ? "Type your code" : "We are sorry, but you cannot access BIOS")
        }
    }

    return render()
}

const PropmtCursor = styled.span`
    color: #FFCE48;
    
    &:after {
        content: "→";
    }
`

export const AccessCodeFormStyled = styled.div`
    min-width: 500px;
    position: absolute;
    top: calc(35%);
    left: calc(50% - 250px);
    border: 1px solid rgba(243,238,255,.08);
    background: rgba(38,24,118,.8);
    border-radius: 8px;

    &:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        border-radius: 8px;
        background:  #17113d 0%;
    }

    @media (max-width: ${Layout.breakpoints.xs}px) {   
        left: 5%;
        width: 90%;
        min-width: unset;
    }

`
