import { useEffect, useRef, useState } from "react";
import { useScreenClass } from "react-grid-system";
import styled from "styled-components";
import { ScreenObserver, UUID, cssTransform, cssTransition } from "../../utilities";
import { TextSection } from "../molecules";
import { Col, Container, Row, Spacer } from "../utilities";
import { SpacerValue } from "../../design_system";



export const Code = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const isMobile = screen.isXs()

    var [called, setCalled] = useState(0)

    const randomProps = () => {
        return {
            badge: UUID.create().slice(0, 10),
            title: `${UUID.create().slice(0, 8)} ${UUID.create().slice(0, 8)}`,
            subtitle: randomSub()
        }
    }

    const randomSub = () => {
        var str = ""

        for (let i = 0; i < 12; i++) {
            str += " " + UUID.create().slice(0, Math.random() * 10 + 2)
        }

        return str.slice(1)
    }

    const [content, setContent] = useState(randomProps())

    const final = {
        badge: "Ledger",
        title: "Real-time Data Curation",
        subtitle: isDesktop ? `All your financial data and payment records universally digested.<br/>
        Manage, query, and download any event under a single ledger of record.`:
            `All your financial data and payment records universally digested.
        Manage, query, and download any event under a single ledger of record.`
    }

    const divRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (isMobile) {
            setContent(final)
            return
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => entry.isIntersecting &&
                codeGenerator()
            );
        });

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        }
    }, []);


    const codeGenerator = () => {
        if (called > 25) {
            setContent(final)
            return;
        }
        else {
            window.setTimeout(() => {
                called++
                setContent(randomProps())
                codeGenerator()
            }, 80)
        }
    };

    const videoHandler = (e: Event) => {
        setTimeout(() => {
            if (videoRef.current) {
                videoRef.current.play();
            }
        }, 3000);
    };

    useEffect(() => {
        if (isMobile) {
            return
        }

        if (videoRef.current) {
            videoRef.current.addEventListener('ended', videoHandler, false);

            return () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('ended', videoHandler, false);
                }
            };
        }
    }, []);

    const render = () =>
        <Container id="section-ledger" mt={-240 as SpacerValue}>
            <Spacer size={420 as SpacerValue} />
            <Row>
                <Col>
                    <div ref={divRef}>
                        <Col position="absolute" top="0px">
                            <VideoContainer>
                                <video ref={videoRef} controls={false} preload="false" muted={true} playsInline={true} autoPlay={true} src="/codewave.webm"></video>
                            </VideoContainer>
                        </Col>
                        <TextSection {...content} />
                    </div>
                </Col>
            </Row>
        </Container>

    return render()
}

const VideoContainer = styled.div`
    pointer-events: none;
    
    -webkit-mask-image: radial-gradient(50% 50% at 50% 50%,#fff 60.94%,transparent 100%);
    mask-image: radial-gradient(50% 50% at 50% 50%,#fff 60.94%,transparent 100%);
    position: absolute;
    top: -360px;
    left: calc(50% - 784px);
    width: 1568px;
    z-index: -1;

    video {
        height: auto;
        width: 100%;
    }
`
// ${cssTransition(".75s cubic-bezier(.6,.6,0,1) opacity")}
// ${cssTransform("translate(-50%,-50%)")}
