import { css } from "styled-components"

export const cssAnimation = (val: string) => css`
    -webkit-animation: ${val};
    -moz-animation: ${val};
    -ms-animation: ${val};
    -o-animation: ${val};
    animation: ${val};
`
export const cssTransition = (val: string) => css`
    -webkit-transition: ${val};
    -moz-transition: ${val};
    -ms-transition: ${val};
    -o-transition: ${val};
    transition: ${val};
`
export const cssTransform = (val: string) => css`
    -webkit-transform: ${val};
    -moz-transform: ${val};
    -ms-transform: ${val};
    -o-transform: ${val};
    transform: ${val};
`