import { useEffect } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { setAccess } from "../../redux"
import { ZoomingStars } from "../atoms"
import { AccessCodeForm } from "../organisms"


export const AccessCode = () => {

    const dispatch = useDispatch()
    useEffect(() => { dispatch(setAccess(false)) }, [])

    const render = () =>
        <AccessCodeContainer>
            <ZoomingStars />
            <AccessCodeForm />
        </AccessCodeContainer>

    return render()
}

export const AccessCodeContainer = styled.div`
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    background: rgb(38,24,118);
    background: radial-gradient(circle, rgba(38,24,118,.5) 0%, rgba(3,0,20,1) 90%);
`