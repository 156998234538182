import { PaletteProps } from "./palette"
import { Columns, SpacerValue } from "./types"

interface LayoutProps {
    columns: Columns
    defaultSpacer: SpacerValue
    background: keyof PaletteProps
    breakpoints: {
        xs: number
        sm: number
        md: number
        lg: number
        xl: number
    }
}

export const Layout: LayoutProps = {
    columns: 12,
    defaultSpacer: 12,
    background: 'dark',
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1400
    }
}
