import styled, { css } from "styled-components"
import { cssAnimation, cssTransform } from "../../utilities"


export const ZoomingStars = () => {
    const render = () =>
        <BackgroundStarsContainer>
            <BackgroundStars $n={1} />
            <BackgroundStars $n={2} />
            <BackgroundStars $n={3} />
            <BackgroundStars $n={4} />
            <BackgroundStars $n={5} />
            <BackgroundStars $n={6} />
            <BackgroundStars $n={7} />
            <BackgroundStars $n={8} />
        </BackgroundStarsContainer>

    return render()
}


const BackgroundStarsContainer = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    overflow: hidden; 
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
`

const BackgroundStars = styled.div<{ $n: number }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    ${cssAnimation("galacticSpaceAnimation 12s infinite")}

    /* background-image: radial-gradient(1px 1px at 20px 80px, #eee, rgba(0, 0, 0, 0)),radial-gradient(1px 1px at 16px 28px, #fff, rgba(0, 0, 0, 0)),radial-gradient(1.5px 2px at 48px 16px, #ddd, rgba(0, 0, 0, 0)); */
    background-image: radial-gradient(1px 1px at 20px 80px, #eee, rgba(0, 0, 0, 0)),radial-gradient(1.5px 2px at 48px 16px, #ddd, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 400px 400px;
    opacity: 0;

    
    ${({ $n }) =>
        $n === 1 ? css`
            background-position: 10% 90%;
            animation-delay: 0s;
    ` : $n === 2 ? css`
            background-position: 20% 50%;
            background-size: 270px 500px;
            animation-delay: .3s;
    ` : $n === 3 ? css`
            background-position: 40% -80%;
            animation-delay: 1.2s;
    ` : $n === 4 ? css`
            background-position: -20% -30%;
            ${cssTransform("rotate(60deg)")}
            animation-delay: 2.5s;
    ` : $n === 5 ? css`
            background-image: radial-gradient(1px 1px at 4px 40px, #eee, rgba(0, 0, 0, 0)),radial-gradient(1px 1px at 8px 4px, #fff, rgba(0, 0, 0, 0)),radial-gradient(1.5px 2px at 60px 16px, #ddd, rgba(0, 0, 0, 0));
            background-position: 80% 30%;
            animation-delay: 4s;
    ` : $n === 6 ? css`
            background-position: 50% 20%;
            animation-delay: 6s;
    ` :$n === 7 ? css`
            background-position: -40% 30%;
            ${cssTransform("rotate(25deg)")}
            animation-delay: 1.2s;
    ` :$n === 8 ? css`
            background-position: 50% 20%;
            ${cssTransform("rotate(45deg)")}
            animation-delay: 3.4s;
    ` : ""}
`

