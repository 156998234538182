import { initializeApp } from 'firebase/app'
import { addDoc, collection, getFirestore } from 'firebase/firestore'
import { useState } from "react"
import { useScreenClass } from "react-grid-system"
import styled from "styled-components"
import { SpacerValue } from "../../design_system"
import { DemoRoutes } from '../../routes'
import { ScreenObserver } from "../../utilities"
import { Button, Icon, Input } from "../atoms"
import { Col, Container, H5, P, Row, S, Spacer, Visible } from "../utilities"


export const Footer = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const isMobile = screen.isEqualOrUnder("xs")

    const [newsletterEmail, setNewsletterEmail] = useState('')
    const [newsletterError, setNewsletterError] = useState('')
    const [newsletterSubscribed, setNewsletterSubscribed] = useState(false)
    const [mouseHoverLinkedin, setMouseHoverLinkedin] = useState(false)
    const [mouseHoverTwitter, setMouseHoverTwitter] = useState(false)

    const render = () => <>
        <Spacer size={310 as SpacerValue} />
        {
            newsletterSubscribed &&
            <Col min_w={isMobile ? '80vw' : '500px'} w={isMobile ? '80vw' : '500px'} max_w={isMobile ? '80vw' : '500px'} position='fixed' bg="rgba(38,24,118,.9)" b_rad='8px' b="1px solid rgba(243,238,255,.08)" top="25vh" left={isMobile ? '10vw' : `calc(50% - 250px)`} zIndex={1000}>
                <Container p={28}>
                    <Col direction='column'>
                        <H5 semiBold>
                            Subscribed
                        </H5>
                        <Spacer size={8} />
                        <P medium>
                            You've been subscribed to BIOS newsletter
                        </P>
                        <Spacer size={12} />
                        <Button w100 variant="secondary" onClick={() => setNewsletterSubscribed(false)}>
                            Close
                        </Button>
                    </Col>
                </Container>
            </Col>
        }
        <FooterContainer>
            <Container>
                <Col xs={12} min_h="2px" max_h="2px" justify="center" bg="linear-gradient(90deg, #1e1b2a 0%, #040014 35%,#040014 65%, #1e1b2a 100%)">
                    <img src="/footer-gradient.png" style={{ width: '750px', height: '2px' }} />
                </Col>
                <Row pt={isDesktop ? 80 : 36} pb={isDesktop ? 96 as SpacerValue : 36} max_w={isDesktop ? "1100px" : "340px"} mx="auto" bb="1px solid rgba(255,255,255,.1)">
                    <Visible till="lg">
                        <Col xs={12} justify="between">
                            <Col xs="content" align="center">
                                <Icon variant="bios" color="white" />
                            </Col>
                            <Col xs="content" align="center">
                                <a onMouseEnter={() => setMouseHoverTwitter(true)} onMouseLeave={() => setMouseHoverTwitter(false)} href="https://twitter.com/enterBIOS" target="_blank">
                                    <Icon variant="twitter" color={mouseHoverTwitter ? "#efedfdd1" : "#efedfd99"} />
                                </a>
                                <Spacer size={24} />
                                <a onMouseEnter={() => setMouseHoverLinkedin(true)} onMouseLeave={() => setMouseHoverLinkedin(false)} href="https://www.linkedin.com/in/fabiocapodagli/" target="_blank">
                                    <Icon variant="linkedin" color={mouseHoverLinkedin ? "#efedfdd1" : "#efedfd99"} />
                                </a>
                            </Col>
                        </Col>
                        <Spacer size={40} />
                    </Visible>

                    <Visible over="lg">
                        <Col direction="column" justify="between">
                            <Col xs="content" align="center">
                                <Icon variant="bios" color="white" />
                            </Col>

                            <Col xs="content" align="center">
                                <a onMouseEnter={() => setMouseHoverTwitter(true)} onMouseLeave={() => setMouseHoverTwitter(false)} href="https://twitter.com/enterBIOS" target="_blank">
                                    <Icon variant="twitter" color={mouseHoverTwitter ? "#efedfdd1" : "#efedfd99"} />
                                </a>
                                <Spacer size={24} />
                                <a onMouseEnter={() => setMouseHoverLinkedin(true)} onMouseLeave={() => setMouseHoverLinkedin(false)} href="https://www.linkedin.com/in/fabiocapodagli/" target="_blank">
                                    <Icon variant="linkedin" color={mouseHoverLinkedin ? "#efedfdd1" : "#efedfd99"} />
                                </a>
                            </Col>
                        </Col>
                    </Visible>

                    <Col xs={12} xl={6}>
                        <Row>
                            <Col xs={6} xl={4} direction="column">
                                <S variant="secondary">Features</S>
                                <Spacer size={20} />
                                <S variant="light" href={DemoRoutes.systemOverview} targetBlank>Overview</S>
                                <Spacer size={20} />
                                <S variant="light" href={DemoRoutes.paymentsLedger} targetBlank>Payments</S>
                                <Spacer size={20} />
                                <S variant="light" href={DemoRoutes.docs} targetBlank>DOCs</S>
                                <Spacer size={20} />
                                <S variant="light" href={DemoRoutes.apiKeys} targetBlank>APIs</S>
                            </Col>
                            <Col xs={6} xl={4} direction="column">
                                <S variant="secondary">Product</S>
                                <Spacer size={20} />
                                <S variant="light" href="#section-system">Payment System</S>
                                <Spacer size={20} />
                                <S variant="light" href="#section-os">Payment OS</S>
                                <Spacer size={20} />
                                <S variant="light" href="#section-ledger">Ledger</S>
                            </Col>
                            <Col xs={6} xl={4} pt={isDesktop ? undefined : 36} direction="column">
                                <S variant="secondary">Our team</S>
                                <Spacer size={20} />
                                <S variant="light">Blog (Coming soon)</S>
                                <Spacer size={20} />
                                <S variant="light" href="mailto:hello@enterbios.com?subject=Hi%20BIOS&body=Hi%20team%20BIOS%20">Contact us</S>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row py={isDesktop ? 56 : 36} max_w={isDesktop ? "1100px" : "340px"} mx="auto" bb="1px solid rgba(255,255,255,.1)" align="center">
                    <Col xs={12} xl={6}>
                        <H5>
                            Join our mailbox
                        </H5>
                        <Spacer size={8} />
                        <S>
                            Stay in the loop with everything BIOS
                        </S>
                    </Col>
                    <Col xs={12} xl={6} pt={isDesktop ? undefined : 24} justify={isDesktop ? "end" : undefined} direction={isDesktop ? undefined : "column"}>
                        <Input onChange={(e) => setNewsletterEmail(e)}>Enter your email</Input>
                        <Spacer size={8} />
                        <Button variant="secondary" w100={!isDesktop} onClick={() => {

                            if (newsletterSubscribed) {
                                return
                            }

                            if (newsletterEmail.length === 0) {
                                setNewsletterError("Type an email")
                                return
                            }

                            const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (!regexp.test(newsletterEmail)) {
                                setNewsletterError("Type a valid email address")
                                return
                            }

                            setNewsletterError('')

                            const firebaseConfig = {
                                apiKey: "AIzaSyB3QPbj4-c9C9u5f2rCj4ruZB1svTf0oe8",
                                authDomain: "bios-82fb8.firebaseapp.com",
                                projectId: "bios-82fb8",
                                storageBucket: "bios-82fb8.appspot.com",
                                messagingSenderId: "649607546461",
                                appId: "1:649607546461:web:40863a1b27e722ca242fb4",
                                measurementId: "G-QTXQ972GB2"
                            };

                            initializeApp(firebaseConfig)
                            const db = getFirestore()
                            const colRef = collection(db, "newsletter")

                            addDoc(colRef, {
                                email: newsletterEmail,
                                timestamp: new Date().getTime()
                            })
                                .then(() => {
                                    setNewsletterSubscribed(true)
                                })
                                .catch((err) => {
                                    setNewsletterError("Server error")
                                })
                        }}>
                            Subscribe
                        </Button>
                    </Col>
                    <Col xs={12} xl={6} />
                    <Col xs={12} xl={6} pt={isDesktop ? undefined : 12}>
                        <S color="#f27777b5">{newsletterError}</S>
                    </Col>
                </Row>
                <Row pt={56} pb={114 as SpacerValue} max_w={isDesktop ? "1100px" : "340px"} mx="auto" align="center">
                    <Col xs={12} xl={6} justify="start" mb={isDesktop ? undefined : 32}>
                        <S medium>
                            Privacy Policy
                        </S>
                        <Spacer size={12} />
                        <S light>•</S>
                        <Spacer size={12} />
                        <S medium>
                            Terms of Conditions
                        </S>
                    </Col>
                    <Col xs={12} xl={6} justify={isDesktop ? "end" : "start"}>
                        <S medium>
                            BIOS All right reserved.
                        </S>
                    </Col>
                </Row>
            </Container>
        </FooterContainer>
    </>

    return render()
}


const FooterContainer = styled.div`
    position: relative;
    background: rgba(147,130,255,.06);
    border-top: 2px solid rgba(255,255,255,.1);
    background: radial-gradient(50% 44.06% at 50% 0%,rgba(147,130,255,.08) 0%,rgba(147,130,255,0) 100%),#030014;
`
