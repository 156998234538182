

export const LadingDomain = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://bios.altervista.org"
export const DemoDomain = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://biosdemo.altervista.org"

export const WebsiteRoutes = {
    index: LadingDomain + '/',
}


export const DemoRoutes = {
    index: DemoDomain + '/',
    docs: DemoDomain + '/docs',
    disputeLedger: DemoDomain + '/disputes',
    sellerLegder: DemoDomain + '/sellers',
    systemOverview: DemoDomain + '/overview',
    paymentsLedger: DemoDomain + '/payins',
    apiKeys: DemoDomain + '/apikeys'
}