import styled, { css } from "styled-components"
import { Typography } from "../../design_system/typography"
import { Container } from "../utilities"
import { cssTransition } from "../../utilities"


export const Button = (props: { children: string, variant: 'primary' | 'secondary', href?: string, w100?: boolean, targetBlank?: boolean, onClick?: () => void }) => {

    const { children, variant, href, w100, targetBlank, onClick } = props

    const render = () =>
        <Container max_w={props.w100 ? undefined : "min-content"}>
            <ButtonStyled $variant={variant} href={href} w100={w100} onClick={onClick} target={targetBlank ? "_blank" : undefined}>
                {children}
                <ButtonBorder />
            </ButtonStyled>
        </Container>

    return render()
}

const ButtonBorder = styled.div``

const ButtonStyled = styled.a<{ $variant: 'primary' | 'secondary', w100?: boolean }>`

    cursor: pointer;
    
    ${p => p.w100 && css`
        width: 100%;
        min-width: 100%;
        text-align: center;
    `}

    backdrop-filter: blur(8px);
    border-radius: 8px;
    color: #f4f0ff;
    display: block;
    padding: 8px 16px;
    ${Typography.S};
    white-space: nowrap;
    font-weight: 500;
    z-index: 2;
    font-feature-settings: "ss01" on,"cv10" on,"calt" off,"liga" off;

    &:before, &:after, ${ButtonBorder} {
        content: "";
        display: block;
        ${cssTransition(".2s opacity cubic-bezier(.6,.6,0,1)")}
    }

    &:before, &:after, ${ButtonBorder} {
        border-radius: inherit;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    ${({ $variant }) => $variant === "primary" ? css`
        ${ButtonBorder} {
            background: linear-gradient(180deg,rgba(207,184,255,.24) 0%,rgba(207,184,255,0) 100%),linear-gradient(0deg,rgba(207,184,255,.32),rgba(207,184,255,.32));
            border-radius: inherit;
            content: "";
            inset: 0;
            -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
            mask-composite: xor;
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            padding: 1px;
            pointer-events: none;
            position: absolute;
        }

        &:before {
            background: linear-gradient(180deg,rgba(60,8,126,0) 0%,rgba(60,8,126,.32) 100%),rgba(113,47,255,.12);
            box-shadow: inset 0 0 12px #bf97ff3d
        }

        &:after {    
            opacity: 0;
            background: linear-gradient(180deg,rgba(60,8,126,0) 0%,rgba(60,8,126,.42) 100%),rgba(113,47,255,.24);
            box-shadow: inset 0 0 12px #bf97ff70;
        }
        
        &:hover {
            &:before {
                opacity: 0;
            }
            &:after {    
                opacity: 1;
            }
        }
                
    `: css`
        ${ButtonBorder} {
            border: 1px solid rgba(243,238,255,.08);
        }
        
        &:before {
            background: linear-gradient(180deg,rgba(243,238,255,0) 0%,rgba(243,238,255,.04) 100%),rgba(147,130,255,.01);
            box-shadow: inset 0 0 12px #ffffff14;
        }

        &:after {    
            opacity: 0;
            box-shadow: inset 0 0 12px #ffffff14;
            background: linear-gradient(180deg,rgba(243,238,255,0) 0%,rgba(243,238,255,.05) 100%),rgba(147,130,255,.04);
        }
        
        &:hover {
            &:before {
                opacity: 0;
            }
            &:after {    
                opacity: 1;
            }
        }
    `}
`

