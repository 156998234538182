import { useEffect } from "react"
import { Code, Footer, Hero, Light, Moon, Navbar, Radar } from "../organisms"
import { Spacer, Visible } from "../utilities"


export const Home = () => {

    useEffect(() => {
        document.body.className = ""
    }, [])

    const render = () =>
        <>
            <Navbar />
            <Spacer size={120} />
            <Hero />
            <Light />
            <Radar />
            <Code />
            <Moon />
            <Footer />
        </>

    return render()
}
