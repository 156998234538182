

export interface PaletteProps {
    primary2: string
    primary1: string

    black: string

    gray: string

    dark: string

    light: string

    white: string

    transparent: string
    unset: string
}


export const Palette: PaletteProps = {
    primary2: '#4263EB',
    primary1: '#CDD6FF',
    
    black: '#030014',

    dark: '#030014',
    
    gray: '#555D67',

    light: '#FFFFFF',

    white: '#FFFFFF',

    transparent: 'transparent',
    unset: 'unset'
}
