import { Align, Direction, Justify, Wrap, Col as _Col, ColProps as _ColProps } from 'react-grid-system';
import styled from 'styled-components';
import { Overflow, Position, SpacerValue } from '../../../design_system';

export interface ColProps extends _ColProps {
    bg?: string
    zIndex?: number
    pointer?: boolean

    overflow?: Overflow
    overflowX?: Overflow
    overflowY?: Overflow

    position?: Position
    left?: string
    right?: string
    top?: string
    bottom?: string

    align?: Align
    justify?: Justify
    direction?: Direction
    wrap?: Wrap

    min_w?: string
    w?: string
    max_w?: string

    min_h?: string
    h?: string
    max_h?: string

    b?: string
    bx?: string
    by?: string
    bl?: string
    br?: string
    bt?: string
    bb?: string

    b_rad?: string

    p?: SpacerValue
    px?: SpacerValue
    py?: SpacerValue
    pl?: SpacerValue
    pr?: SpacerValue
    pt?: SpacerValue
    pb?: SpacerValue

    m?: SpacerValue
    mx?: SpacerValue
    my?: SpacerValue
    ml?: SpacerValue
    mr?: SpacerValue
    mt?: SpacerValue
    mb?: SpacerValue
}

export const Col = (props: ColProps) => {
    var style: React.CSSProperties = props.style ?? {}

    // Default
    style.marginRight = style.marginRight ? style.marginRight : 0
    style.marginLeft = style.marginLeft ? style.marginLeft : 0

    props.xs === "content" && (style.width = "min-content")
    props.bg && (style.background = props.bg)
    props.zIndex && (style.zIndex = props.zIndex)
    props.pointer && (style.cursor = 'pointer')

    props.overflow && (style.overflow = props.overflow)
    props.overflowX && (style.overflowX = props.overflowX)
    props.overflowY && (style.overflowY = props.overflowY)

    props.position && (style.position = props.position)
    props.left && (style.left = props.left)
    props.right && (style.right = props.right)
    props.top && (style.top = props.top)
    props.bottom && (style.bottom = props.bottom)

    props.justify && (style.display = 'flex') && (style.justifyContent = (props.justify === 'around' || props.justify === 'between' ? `space-${props.justify}` : props.justify))
    props.align && (style.display = 'flex') && (style.alignItems = props.align)
    props.direction && (style.display = 'flex') && (style.flexDirection = props.direction)
    props.wrap && (style.display = 'flex') && (style.flexWrap = props.wrap === 'reverse' ? 'wrap-reverse' : props.wrap)

    props.min_w !== undefined && (style.minWidth = props.min_w)
    props.w !== undefined && (style.width = props.w)
    props.max_w !== undefined && (style.maxWidth = props.max_w)

    props.min_h !== undefined && (style.minHeight = props.min_h)
    props.h !== undefined && (style.height = props.h)
    props.max_h !== undefined && (style.maxHeight = props.max_h)

    props.b && (style.border = props.b)
    props.bx && (style.borderLeft = props.bx) && (style.borderRight = props.bx)
    props.by && (style.borderTop = props.by) && (style.borderBottom = props.by)
    props.bt && (style.borderTop = props.bt)
    props.bb && (style.borderBottom = props.bb)
    props.bl && (style.borderLeft = props.bl)
    props.br && (style.borderRight = props.br)

    props.b_rad && (style.borderRadius = props.b_rad)

    props.p !== undefined && (style.padding = `${props.p}px`)
    props.px !== undefined && (style.paddingLeft = `${props.px}px`) && (style.paddingRight = `${props.px}px`)
    props.py !== undefined && (style.paddingTop = `${props.py}px`) && (style.paddingBottom = `${props.py}px`)
    props.pt !== undefined && (style.paddingTop = `${props.pt}px`)
    props.pb !== undefined && (style.paddingBottom = `${props.pb}px`)
    props.pl !== undefined && (style.paddingLeft = `${props.pl}px`)
    props.pr !== undefined && (style.paddingRight = `${props.pr}px`)

    props.m !== undefined && (style.margin = `${props.m}px`)
    props.mx !== undefined && (style.marginLeft = `${props.mx}px`) && (style.marginRight = `${props.mx}px`)
    props.my !== undefined && (style.marginTop = `${props.my}px`) && (style.marginBottom = `${props.my}px`)
    props.mt !== undefined && (style.marginTop = `${props.mt}px`)
    props.mb !== undefined && (style.marginBottom = `${props.mb}px`)
    props.ml !== undefined && (style.marginLeft = `${props.ml}px`)
    props.mr !== undefined && (style.marginRight = `${props.mr}px`)

    const render = () => <ColStyled
        id={props.id}
        style={style}
        offset={props.offset}
        onClick={props.onClick}
        xs={props.xs}
        sm={props.sm} 
        md={props.md} 
        lg={props.lg} 
        xl={props.xl} 
        xxl={props.xxl} 
        className={props.className}
        onMouseDown={props.onMouseDown}
        onMouseOver={props.onMouseOver}
    >{props.children}</ColStyled>

    return render()
}

export const ColStyled = styled(_Col)``