import { ScreenClass } from "react-grid-system"

const screens = ["xs", "sm", "md", "lg", "xl", "xxl"]

export class ScreenObserver {

    public idx: number
    public current: string

    public constructor(screen: ScreenClass) {
        this.current = screen
        this.idx = screens.indexOf(screen)
    }

    isXs() {
        return this.current === "xs"
    }

    isSM() {
        return this.current === "sm"
    }

    isMd() {
        return this.current === "md"
    }

    isLg() {
        return this.current === "lg"
    }
    
    isXl() {
        return this.current === "xl"
    }

    isXxl() {
        return this.current === "xxl"
    }

    isOver(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
        return this.idx > screens.indexOf(breakpoint)
    }

    isEqualOrOver(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
        return this.idx >= screens.indexOf(breakpoint)
    }

    isUnder(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
        return this.idx < screens.indexOf(breakpoint)
    }

    isEqualOrUnder(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
        return this.idx <= screens.indexOf(breakpoint)
    }
}