import { useState } from "react"
import { useScreenClass } from "react-grid-system"
import styled, { css } from "styled-components"
import { Layout } from "../../design_system/layout"
import { Typography } from "../../design_system/typography"
import { DemoRoutes } from "../../routes"
import { ScreenObserver, cssTransition } from "../../utilities"
import { Button, Icon } from "../atoms"
import { Col, Container, Row, Spacer, Visible } from "../utilities"



export const Navbar = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isMobile = screen.isEqualOrUnder("xs")

    const [open, setOpen] = useState(false)

    const scrollTo = (id: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        // Scroll to a certain element
        document.getElementById(id)?.scrollIntoView({
            behavior: "smooth",
        });
        setOpen(false)
    }

    const render = () =>
        <NavbarContainer>
            <NavbarStyled>
                <Container>
                    <Row justify="between" px={isMobile ? undefined : 16}>
                        <Col xs="content" align="center" zIndex={10}>
                            <Icon variant="bios" color="white" />
                        </Col>

                        <Col justify="center" zIndex={1}>
                            <NavbarLinks $open={open}>
                                <NavbarLink onClick={scrollTo("section-system")}>System</NavbarLink>
                                <NavbarLink onClick={scrollTo("section-os")}>Payment OS</NavbarLink>
                                <NavbarLink onClick={scrollTo("section-ledger")} last>Ledger</NavbarLink>
                                <NavbarLink onClick={() => { window.location.href = "https://enterbios.notion.site/About-Us-e529b9ffb31245dd87b67b91642569e8" }} last>About us</NavbarLink>
                            </NavbarLinks>
                        </Col>

                        <Col xs="content" align="center" zIndex={10}>
                            <Button variant="primary" href={DemoRoutes.index} targetBlank>
                                Start Demo
                            </Button>
                            <Visible till="md">
                                <Spacer size={20} />
                                <Hamburger onClick={() => setOpen(!open)}>
                                    <Line $n={1} $open={open} />
                                    <Line $n={2} $open={open} />
                                    <Line $n={3} $open={open} />
                                </Hamburger>
                            </Visible>
                        </Col>
                    </Row>
                </Container>
            </NavbarStyled>
        </NavbarContainer>

    return render()
}

const Line = styled.div<{ $n: number, $open?: boolean }>`
    width: 20px;
    height: 1.5px;
    background-color: #fff;
    border-radius: 500px;
    ${cssTransition("all .3s ease-in-out")}

    ${({ $n }) => $n !== 3 && css`
        margin-bottom: 5px;
    `}

    ${({ $n, $open }) => $open && css`

        ${$n === 1 ? css`
            transform: rotate(45deg) translate(5px,5px);
        `: $n === 2 ? css`
            opacity: 0;
            `: $n === 3 ? css`
                transform: rotate(-45deg) translate(4px,-5px);
            `: ""}
    `}

`

const Hamburger = styled.div`
    padding: 5px;
    cursor: pointer;
`


const NavbarLink = styled.a<{ last?: boolean }>`
    ${Typography.S};
    cursor: pointer;
    color: #ffffffe6;
    display: block;
    ${cssTransition(".3s cubic-bezier(.6,.6,0,1) color")}
    margin-left: 20px;
    margin-right: 20px;
    
    &:hover {
        color: #fff9;
    }

    @media (max-width: ${Layout.breakpoints.md}px) {   
        margin: 0;
        border-top: 1px solid rgba(255,255,255,.1);
        padding: 20px 20px;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #ffffffe6;
        ${p => p.last && css`
            border-bottom: 1px solid rgba(255,255,255,.1);
        `}
    }
`

const NavbarLinks = styled.div<{ $open?: boolean }>`
    background: rgba(255,255,255,.02);
    border: 1px solid rgba(255,255,255,.08);
    border-radius: 999px;
    display: flex;
    left: 50%;
    margin: 0 auto;
    padding: 10px 14px;
    position: absolute;
    z-index: 90;
    transform: translate(-50%);
    
    @media (max-width: ${Layout.breakpoints.md}px) {   

        ${({ $open }) => $open ? css`
            width: 100vw;
            height: 100vh;
            border: 0;
            border-radius: 0;
            display: block;
            left: 0;
            margin: unset;
            padding: 89px 0 0;
            ${cssTransition("transform .45s ease")}
            transform: none;
            position: fixed;
            top: 0;
            z-index: 90;
            background: rgba(3,0,20,.99);
            -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
        `: css`
            visibility: hidden;
            transform: translate(100%);
        `}
    }

`

const NavbarStyled = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 26px 0;
    position: relative;

    &:before {
        background: radial-gradient(62.87% 100% at 50% 100%,rgba(255,255,255,.12) 0%,rgba(255,255,255,0) 100%);
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
    }
`

const NavbarContainer = styled.div`
    padding: 0 20px;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background: rgba(3,0,20,.08);
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`