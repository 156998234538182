import { FlattenSimpleInterpolation, css } from "styled-components"
import { Layout } from "./layout"

export interface TypographyProps {
    family: string
    H1: FlattenSimpleInterpolation
    H2: FlattenSimpleInterpolation
    H3: FlattenSimpleInterpolation
    H4: FlattenSimpleInterpolation
    H5: FlattenSimpleInterpolation
    XL: FlattenSimpleInterpolation
    P: FlattenSimpleInterpolation
    S: FlattenSimpleInterpolation
}


const Family = "'Inter', sans-serif";

export const Typography: TypographyProps = {
    family: Family,
    H1: css`
            font-family: ${Family}; 
            font-size: 72px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 80px;

            @media (max-width: ${Layout.breakpoints.lg}px) {
                    font-size: 44px;
                    line-height: 52px
            }
    `,
    H2: css`
            font-family: ${Family}; 
            font-size: 56px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 64px;

            @media (max-width: ${Layout.breakpoints.lg}px) {
                font-size: 40px;
                line-height: 48px
            }
    ` ,
    H3: css`
            font-family: ${Family}; 
            font-size: 48px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 56px;

            @media (max-width: ${Layout.breakpoints.lg}px) {
                font-size: 36px;
                line-height: 40px
            }
    `,
    H4: css`
            font-family: ${Family}; 
            font-size: 32px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 40px;
    `,
    H5: css`
            font-family: ${Family}; 
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 32px;
    `,
    XL: css`
            font-family: ${Family}; 
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28px;
    `,
    P: css`
            font-family: ${Family}; 
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 24px;
    `,
    S: css`
            font-family: ${Family}; 
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20px;
    `,
}
