import { useEffect, useRef } from "react"
import { useScreenClass } from "react-grid-system"
import styled, { css } from "styled-components"
import { SpacerValue } from "../../design_system"
import { Layout } from "../../design_system/layout"
import { ScreenObserver, cssTransform, cssTransition } from "../../utilities"
import { RadarScanner, RadarScannerContainer, TextSection, Widget } from "../molecules"
import { Col, Container, Row, Spacer } from "../utilities"

// TO CHANGE TRANSFORM

export const Radar = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const isMobile = screen.isXs()

    const domRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (isMobile) {
            imageRef.current && imageRef.current.classList.add("visible")
            return
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => entry.isIntersecting &&
                imageRef.current && imageRef.current.classList.add("visible")
            );
        });

        if (domRef.current) {
            observer.observe(domRef.current);
        }

        return () => {
            if (domRef.current) {
                observer.unobserve(domRef.current);
            }
        }
    }, []);

    const render = () => <>
        <Container>
            <Spacer size={350 as SpacerValue} />
            <Row id="section-os" max_w="985px" mx="auto" justify="center"  mb={375 as SpacerValue} >
                <Col max_w="890px" mb={-375 as SpacerValue} justify="center" position="relative">
                    <div ref={domRef} style={{ top: '50px', position: 'absolute', minWidth: '100%', minHeight: '80%' }} />
                    <RadarContainer ref={imageRef}>
                        <RadarImage src="/radar.png" />
                        <LogoImage src="/logo.png" />
                        <RadarScanner />
                        <RadarFader />
                    </RadarContainer>
                </Col>
                <Col xs={12} mt={-16} pb={isDesktop ? undefined : 24} justify="between">
                    {isDesktop && <VerticalLine left />}
                    <TextSection
                        badge="Payment OS"
                        title="A complete command center"
                        subtitle={isDesktop ? "Scale your global payment infra and monitor the KPIs of your entire payment stack.<br/>The Operating System puts you in complete control." :
                            "Scale your global payment infra and monitor the KPIs of your entire payment stack. The Operating System puts you in complete control."}
                    />
                    {isDesktop && <VerticalLine right />}
                </Col>
                <Col xs={isDesktop ? 11 : "content"} mt={24} justify={isDesktop ? "between" : undefined}>
                    <VerticalLine left />
                    <Widget title="Fully integrated suite of products" subtitle="All you need to receive payments, onboard sellers, send payouts, and everything in between." icon="contracts" />
                    <CentralVerticalLine />
                    <Widget last title="End-to-end payment backbone" subtitle="APIs, DOCs, and a fully white-label approach to run your payment infra and ops under a single OS." icon="security" />
                    <VerticalLine right />
                </Col>
            </Row>
        </Container>
    </>

    return render()
}


const CentralVerticalLine = styled.div`
    left: 50%;
    top: 36px;
    width: 1px;
    height: 180px;
    position: absolute;
    background: linear-gradient(to bottom,rgba(243,238,255,0) -.89%,rgba(243,238,255,.12) 100%);

    @media (max-width: ${Layout.breakpoints.lg}px) {    
        position: absolute;
        width: 100%!important;
        height: 1px!important;
        left: 0!important;
        right: unset!important;
        background: linear-gradient(to left,rgba(243,238,255,.12) -.89%,rgba(243,238,255,0) 100%);
        top: 236px;
    }
`

const VerticalLine = styled.div<{ left?: boolean, right?: boolean }>`
    position: absolute;
    background: linear-gradient(to bottom,rgba(243,238,255,.12) -.89%,rgba(255,255,255,0) 100%);
    height: 100%;
    width: 1px;
    top: 0px;

    left: 50%;
    right: 50%;

    ${p => p.left && css`
        left: 0px;
        right: unset;
    `}

    ${p => p.right && css`
        left: unset;
        right: 0px;
    `}

    @media (max-width: ${Layout.breakpoints.lg}px) {    
        top: -1px;
        position: absolute;
        width: 100%!important;
        height: 1px!important;
        left: 0!important;
        right: unset!important;
        background: linear-gradient(to right,rgba(243,238,255,.12) -.89%,rgba(243,238,255,0) 100%);
        
        ${p => p.right && css`
            bottom: 0;
            top: unset;
        `}
    }
`

const RadarFader = styled.div`
    background: linear-gradient(180deg,rgba(3,0,20,.8) 0%,#030014 37.87%);
    height: 600px;
    position: absolute;
    top: 446px;
    left: calc(50% - 720px);
    width: 1440px;

    &:before {
        background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,.12),rgba(255,255,255,0));
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
`


const LogoImage = styled.img`
    position: absolute;
    height: 80px;
    width: 80px;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 2;
    display: block;
    border: 0;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
`



const RadarImage = styled.img`
    height: 100%;
    width: 100%;
    display: block;
    border: 0;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
`

const RadarContainer = styled.div`
    
    height: 890px;
    position: relative;
    width: 890px;
    min-width: 890px;

    @media (min-width: ${Layout.breakpoints.xs}px) {
        ${RadarFader}, ${RadarScannerContainer}, ${RadarImage}, ${LogoImage} {
            opacity: 0;
            ${cssTransition("opacity 1s  ease-out")}
        }
        
        &.visible {
            ${RadarFader}, ${RadarScannerContainer}, ${RadarImage} {
                ${cssTransition("opacity 1s  ease-out")}
                opacity: 1;
            }
            ${LogoImage} {
                ${cssTransition("opacity .35s  ease-out")}
                opacity: 1;
            }
        }
    }
    
    &:before {
        background: linear-gradient(to left,rgba(243,238,255,.08) -.89%,rgba(243,238,255,0) 100%);
        content: "";
        height: 1px;
        position: absolute;
        right: -23px;
        top: 50%;
        width: 680px;
        z-index: 2;
    }
`


