import { useEffect } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { cssAnimation, cssTransform, cssTransition } from '../../utilities/cssHelpers';
import OrbitControls from "./OrbitControls";

export const GlobeAnimation = () => {

    useEffect(() => {

        var canvas, scene, renderer, data, globe;
        var container = document.querySelector('#globe');

        var elements = {};
        var groups = {
            main: null, // A group containing everything
            globe: null, // A group containing the globe sphere (and globe dots)
            globeDots: null, // A group containing the globe dots
            lines: null, // A group containing the lines between each country
            lineDots: null // A group containing the line dots
        };

        var props = {
            mapSize: {
                width: 2048 / 2,
                height: 1024 / 2
            },
            globeRadius: 200, // Radius of the globe 
            dotsAmount: 20, // Amount of dots to generate and animate randomly across the lines
            startingCountry: 'hongkong', // The key of the country to rotate the camera to during the introduction animation (and which country to start the cycle at)
            colours: {
                // Cache the colours
                globeDots: 'rgb(255, 255, 255)',
                lines: new THREE.Color('#18FFFF'),
                lineDots: new THREE.Color('#18FFFF')
            },
            alphas: {
                globe: 0.7,
                lines: 0.5
            }
        };

        var camera = {
            object: null,
            controls: null,
            angles: {
                current: {
                    azimuthal: null,
                    polar: null
                },
                target: {
                    azimuthal: null,
                    polar: null
                }
            }
        };

        var animations = {
            finishedIntro: false, // Boolean of when the intro animations have finished
            dots: {
                current: 0, // Animation frames of the globe dots introduction animation
                total: 170, // Total frames (duration) of the globe dots introduction animation,
                points: [] // Array to clone the globe dots coordinates to
            },
            globe: {
                current: 0, // Animation frames of the globe introduction animation
                total: 80, // Total frames (duration) of the globe introduction animation,
            },
            countries: {
                active: false, // Boolean if the country elements have been added and made active
                animating: false, // Boolean if the countries are currently being animated
                current: 0, // Animation frames of country elements introduction animation
                total: 120, // Total frames (duration) of the country elements introduction animation
                selected: null, // Three group object of the currently selected country
                index: null, // Index of the country in the data array
                timeout: null, // Timeout object for cycling to the next country
                initialDuration: 5000, // Initial timeout duration before starting the country cycle
                duration: 2000 // Timeout duration between cycling to the next country
            }
        };

        // Boolean to enable or disable rendering when window is in or out of focus
        var isHidden = false;



        /* SETUP */

        function getData() {

            var request = new XMLHttpRequest();
            request.open('GET', 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/617753/globe-points.json', true);

            request.onload = function () {
                if (request.status >= 200 && request.status < 400) {
                    data = JSON.parse(request.responseText);
                    setupScene();
                }
                else {
                    showFallback();
                }
            };

            request.onerror = showFallback;

            request.send();

        }

        function showFallback() {
            // alert('WebGL not supported. Please use a browser that supports WebGL.');
        }

        function setupScene() {

            canvas = container.querySelector('#globe-canvas');

            scene = new THREE.Scene();
            renderer = new THREE.WebGLRenderer({
                canvas: canvas,
                antialias: true,
                alpha: true,
                shadowMapEnabled: false
            });
            renderer.setSize(canvas.clientWidth, canvas.clientHeight);
            renderer.setPixelRatio(1);
            renderer.setClearColor(0x000000, 0);

            // Main group that contains everything
            groups.main = new THREE.Group();
            groups.main.name = 'Main';

            // Group that contains lines for each country
            groups.lines = new THREE.Group();
            groups.lines.name = 'Lines';
            groups.main.add(groups.lines);

            // Group that contains dynamically created dots
            groups.lineDots = new THREE.Group();
            groups.lineDots.name = 'Dots';
            groups.main.add(groups.lineDots);

            // Add the main group to the scene
            scene.add(groups.main);

            // Render camera and add orbital controls
            addCamera();
            addControls();

            // Render objects
            addGlobe();

            // Start the requestAnimationFrame loop
            render();
            animate();

            var canvasResizeBehaviour = function () {

                container.width = '1400px';
                container.height = '1200px';
                container.style.width = '1400px';
                container.style.height = '1200px';

                // container.width = window.innerWidth;
                // container.height = window.innerHeight;
                // container.style.width = window.innerWidth + 'px';
                // container.style.height = window.innerHeight + 'px';

                camera.object.aspect = container.offsetWidth / container.offsetHeight;
                camera.object.updateProjectionMatrix();
                renderer.setSize(container.offsetWidth, container.offsetHeight);

            };

            window.addEventListener('resize', canvasResizeBehaviour);
            window.addEventListener('orientationchange', function () {
                setTimeout(canvasResizeBehaviour, 0);
            });
            canvasResizeBehaviour();

        }



        /* CAMERA AND CONTROLS */

        function addCamera() {
            camera.object = new THREE.PerspectiveCamera(60, canvas.clientWidth / canvas.clientHeight, 1, 10000);
            camera.object.position.z = props.globeRadius * 2.6;
        }

        function addControls() {

            camera.controls = new OrbitControls(camera.object, canvas);
            camera.controls.enableKeys = false;
            camera.controls.enablePan = false;
            camera.controls.enableZoom = false;
            camera.controls.enableDamping = false;
            camera.controls.enableRotate = false;

            // Set the initial camera angles to something crazy for the introduction animation
            camera.angles.current.azimuthal = -Math.PI;
            camera.angles.current.polar = 180;

        }



        /* RENDERING */

        function render() {
            renderer.render(scene, camera.object);
        }

        if ('hidden' in document) {
            document.addEventListener('visibilitychange', onFocusChange);
        }
        else if ('mozHidden' in document) {
            document.addEventListener('mozvisibilitychange', onFocusChange);
        }
        else if ('webkitHidden' in document) {
            document.addEventListener('webkitvisibilitychange', onFocusChange);
        }
        else if ('msHidden' in document) {
            document.addEventListener('msvisibilitychange', onFocusChange);
        }
        else if ('onfocusin' in document) {
            document.onfocusin = document.onfocusout = onFocusChange;
        }
        else {
            window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onFocusChange;
        }

        function onFocusChange(event) {

            var visible = 'visible';
            var hidden = 'hidden';
            var eventMap = {
                focus: visible,
                focusin: visible,
                pageshow: visible,
                blur: hidden,
                focusout: hidden,
                pagehide: hidden
            };

            event = event || window.event;

            if (event.type in eventMap) {
                isHidden = true;
            }
            else {
                isHidden = false;
            }

        }

        function animate() {

            if (isHidden === false) {
                requestAnimationFrame(animate);
            }

            if (groups.globeDots) {
                introAnimate();
            }

            if (animations.finishedIntro === true) {
                animateDots();
            }

            if (animations.countries.animating === true) {
                animateCountryCycle();
            }

            camera.controls.setAzimuthalAngle(Math.cos(Date.now() * 0.0000005) * -360);
            camera.controls.setPolarAngle(1);

            camera.controls.update();

            render();

        }



        /* GLOBE */

        function addGlobe() {

            var textureLoader = new THREE.TextureLoader();
            textureLoader.setCrossOrigin(true);

            var radius = props.globeRadius - (props.globeRadius * 0.02);
            var segments = 64;
            var rings = 64;

            // Make gradient
            var canvasSize = 128;
            var textureCanvas = document.createElement('canvas');
            textureCanvas.width = canvasSize;
            textureCanvas.height = canvasSize;
            var canvasContext = textureCanvas.getContext('2d');
            canvasContext.rect(0, 0, canvasSize, canvasSize);
            var canvasGradient = canvasContext.createLinearGradient(0, 0, 0, canvasSize);
            canvasGradient.addColorStop(1, 'rgba(0,0,0,0.02)');
            canvasGradient.addColorStop(1, 'rgba(0,0,0,0.02)');
            canvasGradient.addColorStop(1, 'rgba(0,0,0,0.02)');
            canvasContext.fillStyle = canvasGradient;
            canvasContext.fill();

            // Make texture
            var texture = new THREE.Texture(textureCanvas);
            texture.needsUpdate = true;

            var geometry = new THREE.SphereGeometry(radius, segments, rings);
            var material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                opacity: 0
            });
            globe = new THREE.Mesh(geometry, material);

            groups.globe = new THREE.Group();
            groups.globe.name = 'Globe';

            groups.globe.add(globe);
            groups.main.add(groups.globe);

            addGlobeDots();

        }

        function addGlobeDots() {

            var geometry = new THREE.Geometry();

            // Make circle
            var canvasSize = 16;
            var halfSize = canvasSize / 2;
            var textureCanvas = document.createElement('canvas');
            textureCanvas.width = canvasSize;
            textureCanvas.height = canvasSize;
            var canvasContext = textureCanvas.getContext('2d');
            canvasContext.beginPath();
            canvasContext.arc(halfSize, halfSize, halfSize, 0, 2 * Math.PI);
            canvasContext.fillStyle = props.colours.globeDots;
            canvasContext.fill();

            // Make texture
            var texture = new THREE.Texture(textureCanvas);
            texture.needsUpdate = true;

            var material = new THREE.PointsMaterial({
                map: texture,
                size: props.globeRadius / 200
            });

            var addDot = function (targetX, targetY) {

                // Add a point with zero coordinates
                var point = new THREE.Vector3(0, 0, 0);
                geometry.vertices.push(point);

                // Add the coordinates to a new array for the intro animation
                var result = returnSphericalCoordinates(
                    targetX,
                    targetY
                );
                animations.dots.points.push(new THREE.Vector3(result.x, result.y, result.z));

            };

            for (var i = 0; i < data.points.length; i++) {
                addDot(data.points[i].x, data.points[i].y);
            }

            for (var country in data.countries) {
                addDot(data.countries[country].x, data.countries[country].y);
            }

            // Add the points to the scene
            groups.globeDots = new THREE.Points(geometry, material);
            groups.globe.add(groups.globeDots);

        }


        /* COUNTRY LINES AND DOTS */

        function addLineDots() {

            var radius = props.globeRadius / 120;
            var segments = 32;
            var rings = 32;

            var geometry = new THREE.SphereGeometry(radius, segments, rings);
            var material = new THREE.MeshBasicMaterial({
                color: props.colours.lineDots
            });

            // Returns a sphere geometry positioned at coordinates
            var returnLineDot = function () {
                var sphere = new THREE.Mesh(geometry, material);
                return sphere;
            };

            for (var i = 0; i < props.dotsAmount; i++) {

                // Get the country path geometry vertices and create the dot at the first vertex
                var targetDot = returnLineDot();
                targetDot.visible = false;

                // Add custom variables for custom path coordinates and index
                targetDot._pathIndex = null;
                targetDot._path = null;

                // Add the dot to the dots group
                groups.lineDots.add(targetDot);

            }

        }

        function assignDotsToRandomLine(target) {

            // Get a random line from the current country
            var randomLine = Math.random() * (animations.countries.selected.children.length - 1);
            randomLine = animations.countries.selected.children[randomLine.toFixed(0)];

            // Assign the random country path to the dot and set the index at 0
            target._path = randomLine._path;

        }

        function reassignDotsToNewLines() {

            for (var i = 0; i < groups.lineDots.children.length; i++) {

                var target = groups.lineDots.children[i];
                if (target._path !== null && target._pathIndex !== null) {
                    assignDotsToRandomLine(target);
                }

            }

        }

        function animateDots() {

            // Loop through the dots children group
            for (var i = 0; i < groups.lineDots.children.length; i++) {

                var dot = groups.lineDots.children[i];

                if (dot._path === null) {

                    // Create a random seed as a pseudo-delay
                    var seed = Math.random();

                    if (seed > 0.99) {
                        assignDotsToRandomLine(dot);
                        dot._pathIndex = 0;
                    }

                }
                else if (dot._path !== null && dot._pathIndex < dot._path.length - 1) {

                    // Show the dot
                    if (dot.visible === false) {
                        dot.visible = true;
                    }

                    // Move the dot along the path vertice coordinates
                    dot.position.x = dot._path[dot._pathIndex].x;
                    dot.position.y = dot._path[dot._pathIndex].y;
                    dot.position.z = dot._path[dot._pathIndex].z;

                    // Advance the path index by 1
                    dot._pathIndex++;

                }
                else {

                    // Hide the dot
                    dot.visible = false;

                    // Remove the path assingment
                    dot._path = null;

                }

            }

        }



        /* ELEMENTS */

        var list;

        function positionElements() {

            var widthHalf = canvas.clientWidth / 2;
            var heightHalf = canvas.clientHeight / 2;

            // Loop through the elements array and reposition the elements
            for (var key in elements) {

                var targetElement = elements[key];

                var position = getProjectedPosition(widthHalf, heightHalf, targetElement.position);

                // Construct the X and Y position strings
                var positionX = position.x + 'px';
                var positionY = position.y + 'px';

                // Construct the 3D translate string
                var elementStyle = targetElement.element.style;
                elementStyle.webkitTransform = 'translate3D(' + positionX + ', ' + positionY + ', 0)';
                elementStyle.WebkitTransform = 'translate3D(' + positionX + ', ' + positionY + ', 0)'; // Just Safari things (capitalised property name prefix)...
                elementStyle.mozTransform = 'translate3D(' + positionX + ', ' + positionY + ', 0)';
                elementStyle.msTransform = 'translate3D(' + positionX + ', ' + positionY + ', 0)';
                elementStyle.oTransform = 'translate3D(' + positionX + ', ' + positionY + ', 0)';
                elementStyle.transform = 'translate3D(' + positionX + ', ' + positionY + ', 0)';

            }

        }



        /* INTRO ANIMATIONS */

        var easeInOutCubic = function (t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        };

        var easeOutCubic = function (t) {
            return (--t) * t * t + 1;
        };

        var easeInOutQuad = function (t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };

        function introAnimate() {

            if (animations.dots.current <= animations.dots.total) {

                var points = groups.globeDots.geometry.vertices;
                var totalLength = points.length;

                for (var i = 0; i < totalLength; i++) {

                    // Get ease value
                    var dotProgress = easeInOutCubic(animations.dots.current / animations.dots.total);

                    // Add delay based on loop iteration
                    dotProgress = dotProgress + (dotProgress * (i / totalLength));

                    if (dotProgress > 1) {
                        dotProgress = 1;
                    }

                    // Move the point
                    points[i].x = animations.dots.points[i].x * dotProgress;
                    points[i].y = animations.dots.points[i].y * dotProgress;
                    points[i].z = animations.dots.points[i].z * dotProgress;

                    // Animate the camera at the same rate as the first dot
                    if (i === 0) {

                        var azimuthalDifference = (camera.angles.current.azimuthal - camera.angles.target.azimuthal) * dotProgress;
                        azimuthalDifference = camera.angles.current.azimuthal - azimuthalDifference;
                        camera.controls.setAzimuthalAngle(azimuthalDifference);

                        var polarDifference = (camera.angles.current.polar - camera.angles.target.polar) * dotProgress;
                        polarDifference = camera.angles.current.polar - polarDifference;
                        //camera.controls.setPolarAngle(polarDifference);

                    }

                }

                animations.dots.current++;

                // Update verticies
                groups.globeDots.geometry.verticesNeedUpdate = true;

                document.getElementById('svg-container').classList.add('active');

            }

            if (animations.dots.current >= (animations.dots.total * 0.65) && animations.globe.current <= animations.globe.total) {

                var globeProgress = easeOutCubic(animations.globe.current / animations.globe.total);
                globe.material.opacity = props.alphas.globe * globeProgress;

                animations.globe.current++;

            }

            if (animations.countries.active === true && animations.finishedIntro === false) {

                animations.finishedIntro = true;
                // Start country cycle
                animations.countries.timeout = setTimeout(showNextCountry, animations.countries.initialDuration);
                addLineDots();

            }

        }



        /* COUNTRY CYCLE */

        function changeCountry(key, init) {

            if (animations.countries.selected !== undefined) {
                animations.countries.selected.visible = false;
            }

            for (var name in elements) {
                if (name === key) {
                    elements[name].element.classList.add('active');
                }
                else {
                    elements[name].element.classList.remove('active');
                }
            }

            // Show the select country lines
            animations.countries.selected = groups.lines.getObjectByName(key);
            animations.countries.selected.visible = true;

            if (init !== true) {

                camera.angles.current.azimuthal = camera.controls.getAzimuthalAngle();
                camera.angles.current.polar = camera.controls.getPolarAngle();

                var targetAngles = returnCameraAngles(data.countries[key].x, data.countries[key].y);
                camera.angles.target.azimuthal = targetAngles.azimuthal;
                camera.angles.target.polar = targetAngles.polar;

                animations.countries.animating = true;
                reassignDotsToNewLines();

            }

        }

        function animateCountryCycle() {

            if (animations.countries.current <= animations.countries.total) {

                var progress = easeInOutQuad(animations.countries.current / animations.countries.total);

                var azimuthalDifference = (camera.angles.current.azimuthal - camera.angles.target.azimuthal) * progress;
                azimuthalDifference = camera.angles.current.azimuthal - azimuthalDifference;
                camera.controls.setAzimuthalAngle(azimuthalDifference);

                var polarDifference = (camera.angles.current.polar - camera.angles.target.polar) * progress;
                polarDifference = camera.angles.current.polar - polarDifference;
                //camera.controls.setPolarAngle(polarDifference);

                animations.countries.current++;

            }
            else {

                animations.countries.animating = false;
                animations.countries.current = 0;

                animations.countries.timeout = setTimeout(showNextCountry, animations.countries.duration);

            }

        }

        function showNextCountry() {

            animations.countries.index++;

            if (animations.countries.index >= Object.keys(data.countries).length) {
                animations.countries.index = 0;
            }

            var key = Object.keys(data.countries)[animations.countries.index];
            changeCountry(key, false);

        }

        /* COORDINATE CALCULATIONS */

        // Returns an object of 3D spherical coordinates
        function returnSphericalCoordinates(latitude, longitude) {

            // Convert latitude and longitude on the 90/180 degree axis
            latitude = ((latitude - props.mapSize.width) / props.mapSize.width) * -180;
            longitude = ((longitude - props.mapSize.height) / props.mapSize.height) * -90;

            // Calculate the projected starting point
            var radius = Math.cos(longitude / 180 * Math.PI) * props.globeRadius;
            var targetX = Math.cos(latitude / 180 * Math.PI) * radius;
            var targetY = Math.sin(longitude / 180 * Math.PI) * props.globeRadius;
            var targetZ = Math.sin(latitude / 180 * Math.PI) * radius;

            return {
                x: targetX,
                y: targetY,
                z: targetZ
            };

        }

        function returnCurveCoordinates(latitudeA, longitudeA, latitudeB, longitudeB) {

            // Calculate the starting point
            var start = returnSphericalCoordinates(latitudeA, longitudeA);

            // Calculate the end point
            var end = returnSphericalCoordinates(latitudeB, longitudeB);

            // Calculate the mid-point
            var midPointX = (start.x + end.x) / 2;
            var midPointY = (start.y + end.y) / 2;
            var midPointZ = (start.z + end.z) / 2;

            // Calculate the distance between the two coordinates
            var distance = Math.pow(end.x - start.x, 2);
            distance += Math.pow(end.y - start.y, 2);
            distance += Math.pow(end.z - start.z, 2);
            distance = Math.sqrt(distance);

            // Calculate the multiplication value
            var multipleVal = Math.pow(midPointX, 2);
            multipleVal += Math.pow(midPointY, 2);
            multipleVal += Math.pow(midPointZ, 2);
            multipleVal = Math.pow(distance, 2) / multipleVal;
            multipleVal = multipleVal * 0.7;

            // Apply the vector length to get new mid-points
            var midX = midPointX + multipleVal * midPointX;
            var midY = midPointY + multipleVal * midPointY;
            var midZ = midPointZ + multipleVal * midPointZ;

            // Return set of coordinates
            return {
                start: {
                    x: start.x,
                    y: start.y,
                    z: start.z
                },
                mid: {
                    x: midX,
                    y: midY,
                    z: midZ
                },
                end: {
                    x: end.x,
                    y: end.y,
                    z: end.z
                }
            };

        }

        // Returns an object of 2D coordinates for projected 3D position
        function getProjectedPosition(width, height, position) {

            position = position.clone();
            var projected = position.project(camera.object);

            return {
                x: (projected.x * width) + width,
                y: -(projected.y * height) + height
            };

        }

        // Returns an object of the azimuthal and polar angles of a given map latitude and longitude
        function returnCameraAngles(latitude, longitude) {

            var targetAzimuthalAngle = ((latitude - props.mapSize.width) / props.mapSize.width) * Math.PI;
            targetAzimuthalAngle = targetAzimuthalAngle + (Math.PI / 2);
            targetAzimuthalAngle = targetAzimuthalAngle + 0.1; // Add a small offset

            var targetPolarAngle = (longitude / (props.mapSize.height * 2)) * Math.PI;

            return {
                azimuthal: targetAzimuthalAngle,
                polar: targetPolarAngle
            };

        }

        /* INITIALISATION */

        if (!window.WebGLRenderingContext) {
            showFallback();
        }
        else {
            getData();
        }



    }, [])

    const render = () =>
        <Animation>
            <GlobeContainer id="globe">
                <SvgContainer id="svg-container">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 984 984" width="984" height="984" style={{ enableBackground: "new 0 0 984 984" }} xmlSpace="preserve">
                        <g>
                            <path d="M968.5,492c0,31.2-3.1,62.4-9.2,93c-6.1,30.6-15.2,60.6-27.2,89.4c-23.9,57.6-59.3,110.4-103.5,154.4
                            c-44.1,44.1-96.9,79.4-154.5,103.2c-28.8,11.9-58.8,21-89.3,27c-30.6,6-61.7,9-92.9,9c-31.1,0-62.3-3.1-92.8-9.2
                            c-30.5-6.1-60.5-15.2-89.2-27.1c-57.5-23.9-110.2-59.3-154.2-103.3c-44-44.1-79.3-96.8-103-154.3c-11.9-28.8-20.9-58.7-26.9-89.2
                            c-6-30.5-9-61.6-9-92.7c0-31.1,3.1-62.2,9.1-92.7C32,368.8,41,338.9,53,310.2c23.8-57.5,59.2-110.1,103.2-154
                            c44-43.9,96.7-79.2,154.1-102.9c28.7-11.9,58.6-20.9,89.1-26.9c30.5-6,61.6-9,92.6-9c31.1,0,62.1,3.1,92.6,9.1
                            c30.5,6.1,60.3,15.1,89,27c57.4,23.8,109.9,59.1,153.8,103.1c43.9,43.9,79.1,96.5,102.8,153.9c11.9,28.7,20.9,58.5,26.9,89
                            c6,30.4,9,61.5,9,92.5H968.5z M966,492c0-31-3.1-62-9.1-92.5c-6-30.4-15.1-60.2-27-88.9c-23.8-57.3-59-109.8-102.9-153.6
                            c-43.9-43.8-96.4-79-153.7-102.6c-28.6-11.8-58.5-20.9-88.9-26.8c-30.4-6-61.4-9-92.4-9c-31,0-62,3.1-92.4,9.1
                            c-30.4,6-60.2,15.1-88.8,27c-57.2,23.7-109.6,58.9-153.4,102.8C113.7,201.3,78.6,253.8,55,311c-11.8,28.6-20.8,58.4-26.8,88.7
                            c-6,30.4-9,61.3-8.9,92.3c0,30.9,3.1,61.9,9.1,92.2c6,30.3,15.1,60.1,26.9,88.7C79,730,114.2,782.4,157.9,826.1
                            c43.8,43.7,96.1,78.8,153.3,102.4c28.6,11.8,58.3,20.8,88.6,26.8c30.3,6,61.2,8.9,92.1,8.9c30.9,0,61.8-3.1,92.1-9.1
                            c30.3-6,60-15,88.5-26.9c57.1-23.7,109.3-58.8,153-102.5c43.7-43.7,78.6-96,102.2-153.1c11.8-28.5,20.8-58.2,26.7-88.5
                            c6-30.3,8.9-61.2,8.9-92H966z" />
                        </g>
                    </svg>
                </SvgContainer>
                <GlobeList />
                <canvas id="globe-canvas"></canvas>
            </GlobeContainer>
        </Animation>

    return render()
}

const GlobeList = styled.ul``
const SvgContainer = styled.div``

const Animation = styled.div`
    width: 1400px;
    min-width: 1400px;
    height: 500px;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgb(3,0,20);
        background: linear-gradient(0deg, rgba(3,0,20,1) 10%, rgba(3,0,20,0) 50%);
    }

`


const GlobeContainer = styled.div`
 	position: relative;
	width: 1400px;
	min-width: 1400px;
 	height: 1200px;
    top: -89px;


    canvas {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 1400px;
        height: 1200px;
    }

    svg{
        height: 100%;
        opacity: .2;
        width: 936px;
        margin: 0 auto;

        path {
            fill: #FFFFFF;
        }
        ${cssAnimation("rotation 55s linear infinite")}
    }

    ${SvgContainer} {
        opacity: 0;
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 80%;
        left: 0;
        right: 0;
        top: 10%;
        z-index: 1;
        display: flex;
        justify-content: center;
        ${cssTransform("scale(0.5)")}
        ${cssTransition("opacity 1s ease-out 1.6s, transform 1s ease-out 1.6s")}
        &.active{
            opacity: 1;
            ${cssTransform("none")}
        }
    }
    
    ${GlobeList} {
        z-index: 10;
        top: 0;
        left: 0;
        display: none;
        list-style: none;
        position: absolute;
        opacity: 0;
        ${cssTransition("opacity 3s cubic-bezier(0.175, 0.885, 0.320, 1.275)")}

        &.active {
         	opacity: 1;
        }

        .text {
            position: absolute;
            opacity: 0.8;
            right: 16px + 5px;
            top: 50%;
            display: block;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            text-align: right;
            text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
            color: #FFFFFF;
            white-space: nowrap;
            ${cssTransform("translateY(-50%)")};
        }

        li {
            opacity: 0.4;
            position: absolute;
            margin-left: -(16px / 2);
            margin-top: -(16px / 2);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #00FFD3;
            ${cssTransition("opacity 1s cubic-bezier(0.175, 0.885, 0.320, 1.275)")}


            &:before {
                content: "";
                opacity: 0.5;
                pointer-events: none;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -(16px / 2);
                margin-top: -(16px / 2);
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #00FFD3;
                ${cssAnimation("2s pulse infinite linear")}
            }

            &.active {
                opacity: 1;
             	background: #FFFFFF;
                margin-left: -(16px + (16px / 2)  / 2);
                margin-top: -(16px + (16px / 2)  / 2);
                width: 16px + (16px / 2);
                height: 16px + (16px / 2);
                
                .text {
                    opacity: 1;
                    right: 16px + (16px / 2)  + 5px;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 700;
                }

                &:before {
                    background: #FFFFFF;
                    margin-left: -(16px + (16px / 2)  / 2);
                    margin-top: -(16px + (16px / 2)  / 2);
                    width: 16px + (16px / 2);
                 	height: 16px + (16px / 2);
                }
            }
        }
    }
`
