import styled from "styled-components"
import { Typography } from "../../design_system/typography"
import { cssTransition } from "../../utilities"
import { Container } from "../utilities"

export const Input = (props: { children?: string, onChange?: (e: string) => void, autoFocus?: boolean }) => {

    const render = () =>
        <Container>
            <InputStyled>
                <input placeholder={props.children} onChange={(e) => { props.onChange && props.onChange(e.currentTarget.value) }} autoFocus={props.autoFocus} />
            </InputStyled>
        </Container>

    return render()
}

const InputStyled = styled.div`
    align-items: center;
    background-color: transparent;
    border: 1px solid rgba(243,238,255,.1);
    border-radius: 8px;
    display: flex;
    ${cssTransition(".15s cubic-bezier(.6,.6,0,1) background-color,.15s cubic-bezier(.6,.6,0,1) border-color,.15s cubic-bezier(.6,.6,0,1) box-shadow")}
    
    &:hover {
        background: rgba(243,238,255,.06);
    }
    
    &:focus {
        outline: none;
    }

    &:focus-within {
        border-color: #9382ff;
        box-shadow: 0 0 0 2px #030014, 0 0 0 3px #9382ff52;
    }

    input {
        ${Typography.S};
        padding: 8px 12px;
        background-color: transparent;
        border: 0;
        color: #f4f0ff;
        display: block;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
`