import { DemoRoutes, WebsiteRoutes } from "../../routes";
import { GlobeAnimation } from "../animation";
import { Button, RisingStars } from "../atoms";
import { Col, Container, H1, Row, Spacer, Visible, XL } from "../utilities";

export const Hero = () => {

    const render = () =>
        <Container>
            <Row wrap="wrap" justify="center" max_w="1500px" mx="auto" position="relative">
                <Col xs={12} pt={56} justify="center" zIndex={15}>
                    <H1 center>
                        A new era of payments
                    </H1>
                </Col>

                <Spacer size={24} />

                <Col xs={12} justify="center" zIndex={15}>
                    <Col px={16}>
                        <XL variant="light" center>
                            <Visible till="xs">
                                Access the best payment providers globally under a single system,
                                with the same benefits and configurations as billion-dollar companies.
                            </Visible>
                            <Visible over="xs">
                                Access the best payment providers globally under a single system,
                                <br />
                                with the same benefits and configurations as billion-dollar companies.
                            </Visible>

                        </XL>
                    </Col>
                </Col>

                <Spacer size={24} />

                <Col xs={12} justify="center" zIndex={15}>
                    <Button variant="secondary" href={DemoRoutes.index} targetBlank>Enter BIOS</Button>
                </Col>

                <GlobeAnimation />
                <div style={{ position: 'absolute', width: '100%', height: '100%', top: '-40px', left: '0px' }}>
                    <RisingStars />
                </div>
            </Row>
        </Container>

    return render()
}
