import { useEffect, useRef } from "react"
import { useScreenClass } from "react-grid-system"
import styled from "styled-components"
import { Layout } from "../../design_system/layout"
import { SpacerValue } from "../../design_system/types"
import { ScreenObserver, cssTransform, cssTransition } from "../../utilities"
import { RisingParticles } from "../atoms"
import { Pillar, TextSection } from "../molecules"
import { Col, Row, Spacer } from "../utilities"


export const Light = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const isMobile = screen.isXs()

    const domRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if(isMobile){
            imageRef.current && imageRef.current.classList.add("visible")
            return;
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => entry.isIntersecting &&
                imageRef.current && imageRef.current.classList.add("visible")
            );
        });

        if (domRef.current) {
            observer.observe(domRef.current);
        }

        return () => {
            if (domRef.current) {
                observer.unobserve(domRef.current);
            }
        }
    }, []);

    const render = () => <>
        <Row pt={350 as SpacerValue} position="relative">
            <div ref={domRef} style={{ width: '100%' }}>
                <Col xs={12} pt={400 as SpacerValue}>
                    <Col id="section-system" position="absolute" top="200px" />
                    <ImageContainer ref={imageRef}>
                        <img src="/light.png" />
                    </ImageContainer>
                    <TextSection
                        badge="Payment System"
                        title="A single source of truth"
                        subtitle={
                            isDesktop ?
                                "We built your ultimate payment integration ever.<br/>One system. One contract. One API." :
                                "We built your ultimate payment integration ever. One system. One contract. One API."}
                    />
                </Col>
            </div>
            <RisingParticles />
        </Row>

        <Spacer size={12} />

        <Row max_w="900px" mx="auto">
            <PillarsContainer>
                <Pillar n={1} title="Enterprise-level pricing" subtitle="Single contract with top-tier rates" icon="pricing" />
                <Pillar n={2} title="No 3rd-party relationships" subtitle="Zero TCO, all native integrations" icon="ui" />
                <Pillar n={3} title="Recouped company resources" subtitle="Save money and time for your team" icon="click" />
                <Pillar n={4} title="Global coverage and currencies" subtitle="Your payment infra runs globally" icon="coverage" />
                <Pillar n={5} title="Ongoing updates and integrations" subtitle="We add stuff, you focus on growth" icon="duplicate" />
            </PillarsContainer>
        </Row >
    </>

    return render()
}

const ImageContainer = styled.div`
    img {
        height: 849px;
        position: absolute;
        top: -50px;
        left: calc(50% - 403px);
        width: 806px;
    }
    
    
    @media (min-width: ${Layout.breakpoints.xs}px) {
        opacity: 0;
        ${cssTransition("opacity 1s ease-out")}

        &.visible {
            ${cssTransition("opacity 1s ease-out")}  
            opacity: 1;
        }
    }
`
// img > ${cssTransform("translate(-50%)")}

const PillarsContainer = styled.div`
    width: 900px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 32px auto 0;
    justify-content: center;
    
    &:before {
        content: "";
        top: 164px;
        height: 1px;
        left: -24px;
        width: 948px;
        position: absolute;
        background: linear-gradient(90deg,rgba(255,255,255,0) -.89%,rgba(255,255,255,.08) 24.33%,rgba(255,255,255,.08) 49.55%,rgba(255,255,255,.08) 74.78%,rgba(255,255,255,0) 100%);
        
        @media (max-width: ${Layout.breakpoints.lg}px) {
            background-color: #ffffff1f;
            content: "";
            height: 100%;
            left: -1px;
            position: absolute;
            top: 0;
            width: 1px;
        }
    }

    @media (max-width: ${Layout.breakpoints.lg}px) {
        width: 326px;
        margin-left: auto;
        margin-right: auto;
    }
`