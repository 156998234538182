import { combineReducers } from "@reduxjs/toolkit"
import { accessReducer } from "./access"


export const reducers = combineReducers({
    access: accessReducer
})

export type State = ReturnType<typeof reducers>

export const REDUX_STATE = (state: State) => state

