import { createGlobalStyle } from "styled-components";
import { PaletteProps } from "./palette";
import { AccessCodeContainer } from "../components/pages/AccessCode";
import { cssTransition } from "../utilities";
import { AccessCodeFormStyled } from "../components/organisms/AccessCodeForm";

export const GlobalStyles = createGlobalStyle<{ palette: PaletteProps }>`
    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
    
    *:focus {
        outline: none;
    }
    
    html, body {
        overflow-x: hidden;
        position: relative;
        margin: 0!important;
    }
    
    body {
        background-color: #030014;
        overflow-y: hidden;    
        color: #fff;
        width: 100vw;

        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    button, span {

    }

    a {
        text-decoration: none;
    }

    @keyframes starsAnimation {
        0% {
            transform: translateZ(0) translateY(0);
        }
        100% {
            transform: translateZ(0) translateY(-2000px);
        }
    }

    @keyframes radarAnimation {
        0% {
            transform: rotate(-201deg);
        }
        100% {
            transform: rotate(159deg);
        }
    }

    @keyframes moonAnimation {
        0% {
            transform: translateY(-530px);
        }

        99% {
            transform: translateY(700px);
        }
        
        100% {
            transform: translateY(-530px);
        }
    }
    
    @keyframes radarDotAnimation {
        0% {
            opacity: 0;
            transform: scale(0)
        }

        7% {
            opacity: 1
        }

        15% {
            opacity: 1;
            transform: scale(1)
        }
        
        22% {
            opacity: 1;
            transform: scale(1)
        }

        30% {
            opacity: 0;
            transform: scale(1.2)
        }

        32% {
            opacity: 0;
            transform: scale(0)
        }

        100% {
            opacity: 0;
            transform: scale(0)
        }
        
    }
    
    @keyframes galacticSpaceAnimation {
       0% {
            opacity: 0;
            transform: scale(0.4);
            transform: rotate(8deg);
            animation-timing-function: ease-in;
        }

        75% {
            opacity: 1;
        }
        
        100% {
            opacity: .2;
            transform: scale(1.5);
        }
    }

    @keyframes satelliteAnimation {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0);
        }
    }

    @keyframes globeEntrance {
        0% {
            transform: translateY(600px) scale(0);
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            transform: translateY(0px) scale(1);
        }
    }

    @-webkit-keyframes pulse {
        0% { -webkit-transform: scale(1); }
        50% { opacity: 0.5; }
        100% { opacity: 0; -webkit-transform: scale(2); }
    }

    @-moz-keyframes pulse {
        0% { -moz-transform: scale(1); }
        50% { opacity: 0.5; }
        100% { opacity: 0; -moz-transform: scale(2); }
    }

    @-ms-keyframes pulse {
        0% { -ms-transform: scale(1); }
        50% { opacity: 0.5; }
        100% { opacity: 0; -ms-transform: scale(2); }
    }

    @-o-keyframes pulse {
        0% { -o-transform: scale(1); }
        50% { opacity: 0.5; }
        100% { opacity: 0; -o-transform: scale(2); }
    }

    @keyframes pulse {
        0% { transform: scale(1); }
        50% { opacity: 0.5; }
        100% { opacity: 0; transform: scale(2); }
    }

    @keyframes rotation {
        0%{
            transform: scaleX(-1) rotate(360deg)
        }
        100%{
            transform: scaleX(-1) rotate(0deg)
        }
    }

    &.authorized {

        ${AccessCodeFormStyled} {
            opacity: 0;
            transform: scale(0);
            ${cssTransition("all 200ms ease-in")}
        }

        ${AccessCodeContainer} {
            transform: scale(4);
            ${cssTransition("all 1s 200ms ease")}
        }

        #root {
            opacity: 0;
            ${cssTransition("opacity 1s 200ms ease-in")}
        }
    }
    
    #root {
        opacity: 1;
        ${cssTransition("opacity 1s 200ms ease-out")}
    }

    #section-system {
    scroll-behavior: smooth;
    }

    @keyframes fade {
        0% { opacity: 0 }
        100% { opacity: 1 }
    }
`


// TO REFACTOR ANIMATIONS