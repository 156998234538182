import { useEffect, useRef, useState } from "react"
import { useScreenClass } from "react-grid-system"
import styled from "styled-components"
import { SpacerValue } from "../../design_system"
import { Layout } from "../../design_system/layout"
import { DemoRoutes } from "../../routes"
import { ScreenObserver, UUID, cssAnimation, cssTransform, cssTransition } from "../../utilities"
import { Badge, Button, RisingStars, RisingStarsStyled } from "../atoms"
import { Col, Container, H1, H3, P, Row, Spacer } from "../utilities"


export const Moon = () => {

    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const isMobile = screen.isXs()

    const domRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (isMobile) {
            imageRef.current && imageRef.current.classList.add("visible")
            return
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => entry.isIntersecting &&
                imageRef.current && imageRef.current.classList.add("visible")
            );
        });

        if (domRef.current) {
            observer.observe(domRef.current);
        }

        return () => {
            if (domRef.current) {
                observer.unobserve(domRef.current);
            }
        }
    }, []);

    const render = () => <>
        <Spacer size={500 as SpacerValue} />
        <Container>
            <Row>
                <Col xs={12}>
                    <Badge>Pricing</Badge>
                    <Spacer size={4} />
                    <H3 center>
                        Plain and simple.<br />Customized to your needs.
                    </H3>
                </Col>
            </Row>
            <Spacer size={56} />
            <Row max_w={isDesktop ? "540px" : "330px"} mx="auto">
                <Col xs={12} pr={16} pb={isDesktop ? 56 : 36} justify={isDesktop ? "center" : "start"} align="center">
                    <H1 variant="gradient" style={
                        isDesktop ?
                            { fontSize: '72px', lineHeight: '80px' } :
                            { fontSize: '58px', lineHeight: '72px' }
                    }>
                        %0.XX
                    </H1>
                    <Spacer size={16} />
                    <P mt={isDesktop ? 12 : 2} variant="light">/GMV<br /> (all&nbsp;included)</P>
                </Col>
                <Col xs={12} justify={isDesktop ? "center" : undefined}>
                    <Row>
                        <Col xs={12} xl="content">
                            <ListElement>Stripe</ListElement>
                            <Spacer size={24} />
                            <ListElement>Paypal</ListElement>
                            <Spacer size={24} />
                            <ListElement>Apple/Google pay</ListElement>
                        </Col>
                        <Spacer size={24} />
                        <Col xs={12} xl="content">
                            <ListElement>iDeal, Bancontact, Sofort</ListElement>
                            <Spacer size={24} />
                            <ListElement>Giropay, Alipay, WeChat Pay</ListElement>
                            <Spacer size={24} />
                            <ListElement>Direct Debits, and more…</ListElement>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} pt={isDesktop ? 48 : 28} justify="center">
                    <div ref={domRef}>
                        <Button w100={!isDesktop} variant="primary" href={DemoRoutes.index} targetBlank>Enter BIOS</Button>
                    </div>
                </Col>
            </Row>
            <ImageContainer ref={imageRef}>
                <MoonImage src="/moon.png" />
                <MoonLine n={0}><div /></MoonLine>
                <MoonLine n={1}><div /></MoonLine>
                <MoonLine n={2}><div /></MoonLine>
                <MoonLine n={3}><div /></MoonLine>
                <MoonLine n={4}><div /></MoonLine>
                <MoonLine n={5}><div /></MoonLine>
                <RisingStars />
            </ImageContainer>
        </Container>
    </>

    return render()
}

const MoonLine = styled.div<{ n: number }>`
    height: 100%;
    left: 0;
    -webkit-mask-size: cover;
    mask-size: cover;
    position: absolute;
    top: 0;
    opacity: 1;
    width: 100%;

    -webkit-mask-image: ${p => `url('/moon-line-${p.n}.png')`};
    mask-image: ${p => `url('/moon-line-${p.n}.png')`};
    
    div {
        ${cssAnimation("moonAnimation 8s infinite cubic-bezier(.62,.62,.14,1)")}
        background: linear-gradient(180deg,rgba(183,164,251,0) 0,#b7a4fb 100%,#8562ff 100%,rgba(133,98,255,0) 0%);
        height: 150px;
        animation-delay: ${p => p.n}s;
    }
`
// ${cssTransform("translateY(-530px)")}

const ImageContainer = styled.div`
    height: 492px;
    position: absolute;
    top: 290px;
    left: calc(50% - 372px);
    width: 744px;
    z-index: -1;

    
    @media (min-width: ${Layout.breakpoints.xs}px) {
        opacity: 0;
        ${cssTransition("opacity 1.5s .15s ease-out")}

        &.visible {
            ${cssTransition("opacity 1.5s .15s ease-out")}
            opacity: 1;
        }
    }
    
    @media (max-width: ${Layout.breakpoints.lg}px) {   
        top: 340px;
    }
    
    ${RisingStarsStyled} {
        border-radius: 50%;
        height: 744px;
        -webkit-mask-image: linear-gradient(180deg,rgba(255,255,255,0) 44.15%,rgba(255,255,255,.35) 71.79%,#fff 100%);
        mask-image: linear-gradient(180deg,rgba(255,255,255,0) 44.15%,rgba(255,255,255,.35) 71.79%,#fff 100%);
        -webkit-mask-size: cover;
        mask-size: cover;
        position: absolute;
        top: -320px;
    }
`
// ${cssTransform("translate(-50%)")}

const MoonImage = styled.img`
    height: 100%;
    width: 100%;
    display: block;
    border: 0;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
`


export const ListElement = (props: { children: string }) => {

    let { children } = props, id = UUID.create()

    const render = () =>
        <Container>
            <Row>
                <Col xs={12} justify="start" wrap="nowrap">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="24" height="24" rx="12" fill={`url(#paint${id})`} fillOpacity="0.08" />
                        <g filter={`url(#filter${id})`}>
                            <path d="M12.5 9L15.5 12L12.5 15L9.5 12L12.5 9Z" fill="#F3EEFF" />
                        </g>
                        <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#F3EEFF" strokeOpacity="0.06" />
                        <defs>
                            <filter id={`filter${id}`} x="3.5" y="3" width="18" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="3" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_289_16017" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_289_16017" result="shape" />
                            </filter>
                            <linearGradient id={`paint${id}`} x1="12.5" y1="0" x2="12.5" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#F3EEFF" stopOpacity="0" />
                                <stop offset="1" stopColor="#F3EEFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <Spacer size={12} />
                    <P variant="secondary" nowrap>
                        {children}
                    </P>
                </Col>
            </Row>
        </Container>

    return render()
}

