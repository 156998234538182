import styled from "styled-components"
import { Degrees } from "../../design_system"
import { cssAnimation, cssTransform } from "../../utilities"


export const RadarScanner = () => {

    const render = () =>
        <RadarScannerContainer>
            <Scanner />
            <ScannerDots />
        </RadarScannerContainer>

    return render()
}

export const RadarScannerContainer = styled.div`
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
`

const Scanner = styled.div`
    ${cssAnimation("radarAnimation 10s infinite linear")}
    -webkit-clip-path: polygon(0 0,93.3673469388% 0,93.3673469388% 25.1275510204%,50% 50.1275510204%,0 50.1275510204%);
    clip-path: polygon(0 0,93.3673469388% 0,93.3673469388% 25.1275510204%,50% 50.1275510204%,0 50.1275510204%);
    height: 784px;
    left: 0;
    left: 53px;
    position: absolute;
    top: 0;
    top: 53px;
    ${cssTransform("rotate(-201deg)")}
    width: 784px;

    &:before, &:after {
        content: "";
    }

    &:before {
        background: linear-gradient(267deg,rgba(99,102,241,.4) 13.23%,rgba(3,0,20,0) 50%);
        border-radius: 50%;
        display: block;
        height: 100%;
        width: 100%;
    }

    &:after {
        background: linear-gradient(to bottom,rgba(120,123,255,.24) .11%,rgba(120,123,255,0) 100%),linear-gradient(to bottom,rgba(255,255,255,.24) .11%,rgba(255,255,255,0) 100%);
        height: 392px;
        left: 50%;
        position: absolute;
        top: 0;
        ${cssTransform("rotate(60deg)")}
        transform-origin: bottom;
        width: 1px;
    }
`


export const ScannerDots = () => {

    const render = () =>
        <div>
            <ScannerDot left={107.5} top={395} deg={0} />
            <ScannerDot left={230} top={298.5} deg={30} />
            <ScannerDot left={252} top={145.5} deg={60} />
            <ScannerDot left={395.5} top={203.2} deg={90} />
            <ScannerDot left={539} top={146} deg={120} />
            <ScannerDot left={561} top={299.5} deg={150} />
            <ScannerDot left={683} top={395.2} deg={180} />
        </div>

    return render()
}


export const ScannerDot = (props: { left: number, top: number, deg: Degrees }) => {

    const { left, top, deg } = props

    const del = (deg + 46.8) * (10 / 360)

    const render = () =>
        <ScannerDotContainer left={left} top={top}>
            <DotCircle n={6} size={100} pos={50} del={del} />
            <DotCircle n={5} size={68} pos={34} del={del} />
            <DotCircle n={4} size={36} pos={18} del={del} />
            <DotShadow n={3} size={12} pos={6} tr={16} del={del} />
            <DotShadow n={2} size={8} pos={4} tr={48} del={del} />
            <FirstDotShadow del={del} />
        </ScannerDotContainer>

    return render()
}

const ScannerDotContainer = styled.div<{ left: number, top: number }>`
    left: ${p => p.left}px;
    top: ${p => p.top}px;
    height: 100px;
    position: absolute;
    width: 100px;
`

const DotCircle = styled.div<{ n: number, size: number, pos: number, del: number }>`
    height: ${p => p.size}px;
    width: ${p => p.size}px;
    left: ${p => `calc(50% - ${p.pos}px)`};
    top: ${p => `calc(50% - ${p.pos}px)`};
    
    background: rgba(109,72,255,.04);
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    ${cssTransform("scale(0)")}
    
    ${cssAnimation("radarDotAnimation 10s cubic-bezier(.6,.6,0,1)")}
    animation-delay: ${p => p.del + (p.n / 100)}s;
    animation-iteration-count: infinite;
`


const DotShadow = styled.div<{ n: number, size: number, pos: number, tr: number, del: number }>`
    height: ${p => p.size}px;
    width: ${p => p.size}px;
    left: ${p => `calc(50% - ${p.pos}px)`};
    top: ${p => `calc(50% - ${p.pos}px)`};

    background: ${p => `rgba(109,72,255,.${p.tr})`};
    box-shadow: inset 0 0 6px 4px #ed78ff0a, inset 0 0 8px #787bff0f, inset 0 0 4px #787bff0a;
    
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    ${cssTransform("scale(0)")}
    
    ${cssAnimation("radarDotAnimation 10s cubic-bezier(.6,.6,0,1)")}
    animation-delay: ${p => p.del + (p.n / 100)}s;
    animation-iteration-count: infinite;
`

const FirstDotShadow = styled.div<{ del: number }>`
    height: 20px;
    width: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    background: rgba(109,72,255,.76);
    filter: blur(8px);
    mix-blend-mode: screen;
    ${cssTransform("scale(-1)")}

    border-radius: 50%;
    opacity: 0;
    position: absolute;
    
    ${cssAnimation("radarDotAnimation 10s cubic-bezier(.6,.6,0,1)")}
    animation-delay: ${p => p.del + 0.01}s;
    animation-iteration-count: infinite;
`