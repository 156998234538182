import { ReactNode } from 'react';
import { useScreenClass } from 'react-grid-system';

type BreakpointsValue = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface VisibleStyles {
    justFor?: BreakpointsValue[]
    exceptFor?: BreakpointsValue[]
    till?: BreakpointsValue
    over?: BreakpointsValue
    children: ReactNode | ReactNode[]
}

export const Visible = (props: VisibleStyles) => {
    const screenClass = useScreenClass()
  
    if (props.justFor) {
        return props.justFor.includes(screenClass as BreakpointsValue) ?
            <>{props.children}</> : <></>
    }

    else if (props.exceptFor) {
        return props.exceptFor.includes(screenClass as BreakpointsValue) ?
            <></> : <>{props.children}</>
    }

    let screens = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    let allowedOver = props.over ? screens.slice(screens.indexOf(props.over) + 1) : undefined
    let allowedTill = props.till ? screens.slice(0, screens.indexOf(props.till) + 1) : undefined
    let screenAllowed = allowedOver ? allowedOver.concat(allowedTill ?? []) : allowedTill ? allowedTill.concat(allowedOver ?? []) : screens

    return screenAllowed.includes(screenClass) ?
        <>{props.children}</> : <></>
}
