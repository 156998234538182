import React, { ReactNode, RefObject } from "react";
import styled, { css } from "styled-components";
import { SpacerValue, Weight } from "../../design_system";
import { Typography, TypographyProps } from "../../design_system/typography";


export interface TextProps {

  negative?: boolean

  id?: string
  variant?: 'primary' | 'secondary' | 'light' | 'gradient'
  color?: string
  spacing?: string
  nowrap?: boolean
  wrap?: boolean
  ref?: RefObject<HTMLHeadingElement>
  href?: string
  targetBlank?: boolean

  thin?: boolean
  extraLight?: boolean
  light?: boolean
  regular?: boolean
  medium?: boolean
  semiBold?: boolean
  bold?: boolean
  extraBold?: boolean
  black?: boolean

  weight?: Weight
  uppercase?: boolean
  capitalize?: boolean

  center?: boolean
  left?: boolean
  right?: boolean

  max_w?: number

  m?: SpacerValue
  mx?: SpacerValue
  my?: SpacerValue
  mt?: SpacerValue
  mr?: SpacerValue
  mb?: SpacerValue
  ml?: SpacerValue

  hoverColor?: string

  children?: ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const shared = css<TextProps>`
  margin-bottom: 0px;
  margin-top: 0px;

  ${p => p.onClick && css` cursor: pointer; `}
  ${p => p.hoverColor && css` color: ${p.hoverColor}; `}
  ${p => p.uppercase && css` text-transform: uppercase; `}
  ${p => p.capitalize && css` text-transform: capitalize; `}

  &, a, span {
    
    ${p => p.color !== undefined ? css`
      color: ${p.color};
     `: p.variant === "primary" ? css`
      background: linear-gradient(180deg,#fff 0%,rgba(255,255,255,.7) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    `: p.variant === "secondary" ? css`
      color: #f4f0ff;
    `: p.variant === "light" ? css`
      color: #efedfdb3;
    `: css`
      background: linear-gradient(90.01deg,#e59cff .01%,#ba9cff 50.01%,#9cb2ff 100%),linear-gradient(180deg,rgba(255,255,255,.4) 0%,rgba(255,255,255,.28) 100%);
      background-blend-mode: screen,normal;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
    
    ${p => p.wrap && css`
      display: flex;
      flex-wrap: wrap !important;
      white-space: break-spaces;
    `}

    ${p => p.nowrap && css` 
      display: flex;
      align-items: center;
      flex-wrap: nowrap !important;
      white-space: nowrap;
    `}

    ${p => p.spacing && css` 
      letter-spacing: ${p.spacing};
    `}

    ${p => p.max_w && css`
      max-width: ${p.max_w}px;
      ${p.mx === undefined && p.ml === undefined && p.mr === undefined && css`
        margin-left: auto;
        margin-right: auto;
      `}
    `}
  }

  
  ${p =>
    p.center ? css`
      text-align: center;
      display: flex;
      justify-content: center;
  `:
      p.right ? css`
      text-align: right;
  `: css`
      text-align: left;
  `}

  ${p => p.thin && css`font-weight: 100;`}
  ${p => p.extraLight && css`font-weight: 200;`}
  ${p => p.light && css`font-weight: 300;`}
  ${p => p.regular && css`font-weight: 400;`}
  ${p => p.medium && css`font-weight: 500;`}
  ${p => p.semiBold && css`font-weight: 600;`}
  ${p => p.bold && css`font-weight:  700;`}
  ${p => p.extraBold && css`font-weight:  800;`}
  ${p => p.black && css`font-weight:  900;`}
  ${p => p.weight && css`font-weight: ${p.weight};`}
  
  ${p => p.m !== undefined && css`margin:${p.m}px;`}
  ${p => p.mx !== undefined && css`margin-left:${p.mx}px;margin-right:${p.mx}px;`}
  ${p => p.my !== undefined && css`margin-top:${p.my}px;margin-bottom:${p.my}px;`}
  ${p => p.mt !== undefined && css`margin-top:${p.mt}px;`}
  ${p => p.mr !== undefined && css`margin-right:${p.mr}px;`}
  ${p => p.mb !== undefined && css`margin-bottom:${p.mb}px;`}
  ${p => p.ml !== undefined && css`margin-left:${p.ml}px;`}
`


const Text = (props: { textProps: TextProps, variant: keyof TypographyProps }) => {
  var { textProps, variant } = props

  const p: TextProps = {
    ...textProps,
    variant: textProps.variant ?? "primary",
    children: textProps.href === undefined ? textProps.children :
      <a href={textProps.href} target={textProps.targetBlank ? '_blank' : undefined}>
        {textProps.children}
      </a>
  }

  return variant === "H1" ? <H1Styled {...{ ...p }} /> :
    variant === "H2" ? <H2Styled {...{ ...p }} /> :
      variant === "H3" ? <H3Styled {...{ ...p }} /> :
        variant === "H4" ? <H4Styled {...{ ...p }} /> :
          variant === "H5" ? <H5Styled {...{ ...p }} /> :
            variant === "XL" ? <XLStyled {...{ ...p }} /> :
              variant === "P" ? <PStyled {...{ ...p }} /> : <SStyled {...{ ...p }} />
}

export const H1 = (props: TextProps) => <Text textProps={props} variant="H1" />
export const H2 = (props: TextProps) => <Text textProps={props} variant="H2" />
export const H3 = (props: TextProps) => <Text textProps={props} variant="H3" />
export const H4 = (props: TextProps) => <Text textProps={props} variant="H4" />
export const H5 = (props: TextProps) => <Text textProps={props} variant="H5" />
export const XL = (props: TextProps) => <Text textProps={props} variant="XL" />
export const P = (props: TextProps) => <Text textProps={props} variant="P" />
export const S = (props: TextProps) => <Text textProps={props} variant="S" />

export const H1Styled = styled.h1<TextProps>`${Typography.H1};${shared}`;
export const H2Styled = styled.h2<TextProps>`${Typography.H2};${shared}`;
export const H3Styled = styled.h3<TextProps>`${Typography.H3};${shared}`;
export const H4Styled = styled.h4<TextProps>`${Typography.H4};${shared}`;
export const H5Styled = styled.h5<TextProps>`${Typography.H5};${shared}`;
export const XLStyled = styled.span<TextProps>`${Typography.XL};${shared}`;
export const PStyled = styled.span<TextProps>`${Typography.P};${shared}`;
export const SStyled = styled.span<TextProps>`${Typography.S};${shared}`;