import React from "react"
import { useScreenClass } from "react-grid-system"
import { ScreenObserver } from "../../utilities"
import { Badge } from "../atoms"
import { Col, H2, P, Spacer } from "../utilities"


export const TextSection = (props: { badge: string, title: string, subtitle: string }) => {

    var n = 0
    const screen = new ScreenObserver(useScreenClass())
    const isDesktop = screen.isOver("lg")
    const { badge, title, subtitle } = props

    const render = () =>
        <Col zIndex={3}>
            <Badge>{badge}</Badge>
            <H2 center max_w={isDesktop ? undefined : 330}>{title}</H2>
            <Spacer size={16} />
            <P variant="light" max_w={isDesktop ? 700 : 330} center>
                {
                    subtitle
                        .split('<br/>')
                        .map(x =>
                            <React.Fragment key={n++}>
                                {x}
                                <br />
                            </React.Fragment>
                        )
                }
            </P>
        </Col>

    return render()
}
