import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccessCode, Home } from "./components/pages";
import { GlobalStyles, Palette } from "./design_system";
import { REDUX_STATE, updateTimestamp } from "./redux";


const App = () => {

    const dispatch = useDispatch()
    useEffect(() => { dispatch(updateTimestamp()) }, [])

    const { canAccess, lastUpdate } = useSelector(REDUX_STATE).access

    const render = () => <>
        <GlobalStyles palette={Palette} />
        {
            canAccess && lastUpdate > new Date().setHours(new Date().getHours() - 1) ?
                <Home /> : <AccessCode />
        }
    </>

    return render()
}

export default App;
